
import React from 'react';

const Disclaimer = () => {
    return (
        <div className="container mx-auto px-6 md:px-8 lg:px-12 py-8">
            <h1>Impressum</h1>
            <p>WEBSEITENBETREIBER
                Kai Detmers
                INFORMATIONEN ÜBER DAS UNTERNEHMEN
                Andere Kommodore-Ziegenbein-Allee 10 Bremen , 28217 t3mr0i@gmail.com 01734038349
                INFORMATIONEN ÜBER DIE UNTERNEHMENSREGISTRIERUNG
                Informationen zur Online-Streitbeilegung: Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS- Plattform“) geschaffen. Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Sie können die OS-Plattform unter dem folgenden Link erreichen: http://ec.euopa.eu/consumers/odr „Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG).</p>
        </div>
    );
};

export default Disclaimer;
