import axios from 'axios';

const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

const apiInstance = axios.create({
    baseURL: 'https://api.kickbase.com',
    headers,
});
const playerInfoCache = {};
const leagueStatsCache = {};
const playerPointsCache = {};
const marketPlayersCache = {};
let leaguesResponse = null;
apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

function logout() {
    localStorage.removeItem('token');
}
export const login = async (email, password) => {
    try {
        const response = await apiInstance.post('/user/login', {
            email,
            password,
            ext: false
        });

        // Check if the response status is not OK
        if (response.status !== 200) {
            console.error('Login error with status code:', response.status);
            throw new Error(`Server responded with status: ${response.status}`);
        }

        if (response.data && response.data.token) {
            localStorage.setItem('token', response.data.token);  // store token
        }
        return response.data;
    } catch (error) {
        console.error('Login request failed:', error);
        throw error;
    }
};


export const resetPassword = async (password, token) => {
    try {
        const response = await apiInstance.post('/user/resetpassword', {
            password,
            token,
        }, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Reset password request failed:', error);
        throw error;
    }
};
export const getLeagueUserInfo = async (token) => {
    try {
        const response = await apiInstance.get('/leagues', {
            params: {
                token: token,
            },
            headers,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403 || error.response.status === 401) {
            // Token is invalid or expired, log the user out
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.reload(); // or redirect to login page
        }
        console.error('Get league user info request failed:', error);
        throw error;
    }
};

export const getUserInfo = async (token, leagueId) => {
    try {
        console.log('Calling getUserInfo with token:', token, 'and leagueId:', leagueId);
        const response = await apiInstance.get(`/leagues/${leagueId}/me`, {
            params: {
                token: token,
            },
            headers,
        });
        console.log('getUserInfo response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Get user info request failed:', error);
        throw error;
    }
};


const getHistoricalData = async (playerId) => {
    try {
        const response = await apiInstance.get(`/getPlayer/${playerId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching historical values:", error);
        return [];
    }
};

export const getLeagueQuickStats = async (leagueId) => {
    try {
        console.log('Fetching quick stats for league:', leagueId);
        const response = await apiInstance.get(`/leagues/${leagueId}/quickstats`, {
            headers,
            withCredentials: true,
        });
        console.log('Quick stats response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Get league quick stats failed:', error);
        throw error;
    }
};

export const getUserMatchDayFeed = async (leagueId, userId) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/users/${userId}/feed`, {
            headers,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Get user match day feed request failed:', error);
        throw error;
    }
};

export const getLeagues = async () => {
    try {
        const response = await apiInstance.get('/leagues', {
            headers: {
                Accept: 'application/json',
            },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Get leagues request failed:', error);
        throw error;
    }
};

export const refreshChatToken = async () => {
    try {
        const response = await apiInstance.post('/user/refreshchattoken', null, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Refresh chat token request failed:', error);
        throw error;
    }
};

// Player Endpoints
// Define a cache object outside of your function

export const getPlayerInfo = async (leagueId, playerId) => {
    // Check if leagueId is null and fetch leagues
    if (leagueId === null && leaguesResponse === null) {
        try {
            leaguesResponse = await getLeagues();
            const leagues = leaguesResponse.leagues;
            if (leagues.length > 0) {
                leagueId = leagues[0].id; // Use the first league's ID
            } else {
                throw new Error('No leagues found');
            }
        } catch (error) {
            console.error('Error fetching leagues:', error);
            throw error;
        }
    }

    // Generate a unique key for each combination of leagueId and playerId
    const cacheKey = `${leagueId}_${playerId}`;

    // Check if data for this combination is already in cache
    if (playerInfoCache[cacheKey]) {
        return playerInfoCache[cacheKey];
    }

    // If not in cache, proceed to call the API
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/users/${playerId}/players`, {
            headers,
        });
        // Store the response in cache
        playerInfoCache[cacheKey] = response.data;

        return response.data;
    } catch (error) {
        console.error('Get player info request failed:', error);
        throw error;
    }
};



export const getPlayerData = async (playerId) => {
    try {
        const response = await apiInstance.get(`/getPlayer/${playerId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching player data:', error);
        throw error;
    }
};

export const getPlayerFeed = async (leagueId, playerId) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/players/${playerId}/feed`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Get player feed request failed:', error);
        throw error;
    }
};


// Define a cache object outside of your function

export const getPlayerPoints = async (playerId) => {
    // Check if data for this playerId is already in cache
    if (playerPointsCache[playerId]) {
        return playerPointsCache[playerId];
    }

    // If not in cache, proceed to call the API
    try {
        const response = await apiInstance.get(`/players/${playerId}/points`, {
            headers,
        });

        // Store the response in cache
        playerPointsCache[playerId] = response.data;

        return response.data;
    } catch (error) {
        console.error('Get player points request failed:', error);
        throw error;
    }
};


let teamRankingCache = {};

export const getTeamRanking = async () => {
    // Check if data is already in cache
    if (teamRankingCache['ranking']) {
        return teamRankingCache['ranking'];
    }

    // If not in cache, proceed to call the API
    try {
        const response = await apiInstance.get('/live/teamranking', {
            headers,
        });

        // Store the response in cache
        teamRankingCache['ranking'] = response.data;

        return response.data;
    } catch (error) {
        console.error('Get team ranking request failed:', error);
        throw error;
    }
};

// Define a cache object outside of your function

export const getLeagueStats = async (leagueId, token) => {
    // Check if data for this leagueId is already in cache
    if (leagueId === null && leaguesResponse === null) {
        try {
            const leaguesResponse = await getLeagues();
            const leagues = leaguesResponse.leagues;
            if (leagues.length > 0) {
                leagueId = leagues[0].id; // Use the first league's ID
            } else {
                throw new Error('No leagues found');
            }
        } catch (error) {
            console.error('Error fetching leagues:', error);
            throw error;
        }
    }
    if (leagueStatsCache[leagueId]) {
        return leagueStatsCache[leagueId];
    }

    // If not in cache, proceed to call the API
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/stats`, {
            headers,
        });

        // Store the response in cache
        leagueStatsCache[leagueId] = response.data;

        return response.data;
    } catch (error) {
        console.error('Get league stats request failed:', error);
        throw error;
    }
};

export const getPlayerStats = async (leagueId, playerId, token) => {
    if (!leagueId) {
        console.error('LeagueId is undefined in getPlayerStats');
        return { error: 'LeagueId is required to fetch player stats' };
    }

    // Extract the actual player ID from the combined ID
    const actualPlayerId = playerId.toString().slice(-4);

    const sessionKey = `playerStats_${leagueId}_${actualPlayerId}`;
    const cachedStats = sessionStorage.getItem(sessionKey);

    try {

        const response = await apiInstance.get(`/leagues/${leagueId}/players/${actualPlayerId}/stats`, {
            headers,
        });
        // Store data with a timestamp
        const dataToCache = {
            data: response.data,
            timestamp: Date.now()
        };
        sessionStorage.setItem(sessionKey, JSON.stringify(dataToCache));
        return response.data;
    } catch (error) {
        console.error(`Get player stats request failed for player ${actualPlayerId} in league ${leagueId}:`, error);
        return { error: error.message };
    }
};



// Add this new function to fetch the league feed
export const getLeagueFeed = async (leagueId, start = 0) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/feed?start=${start}`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Get league feed request failed:', error);
        throw error;
    }
};



// Add this function to get a player's market value at a specific date
export const getPlayerValueAtDate = async (leagueId, playerId) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/players/${playerId}/stats`, {
            headers,
        });
        return response.data.marketValues;
    } catch (error) {
        console.error('Get player value at date request failed:', error);
        return [];
    }
};

export const searchCompetitionPlayers = async (query) => {
    try {
        const response = await apiInstance.get(`/competition/search?query=${query}`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Search competition players request failed:', error);
        throw error;
    }
};

// Define a cache object outside of your functions

export const getMarketPlayers = async (leagueId) => {
    // Handle the case where leagueId is null
    if (leagueId === null && leaguesResponse === null) {
        try {
            const leaguesResponse = await getLeagues(); // Assuming getLeagues does not require a token
            const leagues = leaguesResponse.leagues;
            if (leagues.length > 0) {
                leagueId = leagues[0].id; // Use the first league's ID
            } else {
                throw new Error('No leagues found');
            }
        } catch (error) {
            console.error('Error fetching leagues:', error);
            throw error;
        }
    }

    // Check if data for this leagueId is already in cache
    if (marketPlayersCache[leagueId]) {
        console.log("Returning cached market players for league:", leagueId);
        return marketPlayersCache[leagueId];
    }

    // If not in cache, proceed to call the API
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/market`, {
            headers,
        });

        // Store the response in cache
        marketPlayersCache[leagueId] = response.data;

        return response.data;
    } catch (error) {
        console.error('Get market players request failed:', error);
        throw error;
    }
};


export const removePlayerFromMarket = async (leagueId, playerId) => {
    try {
        const response = await apiInstance.delete(`/leagues/${leagueId}/market/${playerId}`, {
            headers,
        });

        // Invalidate or update the cache for this leagueId as the market data has changed
        delete marketPlayersCache[leagueId];

        return response.data;
    } catch (error) {
        console.error('Remove player from market request failed:', error);
        throw error;
    }
};


export const addPlayerToMarket = async (leagueId, playerId, price) => {
    try {
        const response = await apiInstance.post(`/leagues/${leagueId}/market`, {
            playerId,
            price,
        }, {
            headers,
        });

        return response.data;
    } catch (error) {
        console.error('Add player to market request failed:', error);
        throw error;
    }
};


export const acceptOffer = async (leagueId, playerId, offerId) => {
    try {
        const response = await apiInstance.post(`/leagues/${leagueId}/market/${playerId}/offers/${offerId}/accept`, null, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Accept offer request failed:', error);
        throw error;
    }
};

export const updatePrice = async (leagueId, playerId, price) => {
    try {
        const response = await apiInstance.put(`/leagues/${leagueId}/market/${playerId}`, {
            price,
        }, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Update price request failed:', error);
        throw error;
    }
};

export const declineOffer = async (leagueId, playerId, offerId) => {
    try {
        const response = await apiInstance.post(`/leagues/${leagueId}/market/${playerId}/offers/${offerId}/decline`, null, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Decline offer request failed:', error);
        throw error;
    }
};

export const placeOffer = async (leagueId, playerId, price) => {
    try {
        const response = await apiInstance.post(`/leagues/${leagueId}/market/${playerId}/offers`, {
            price,
        }, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Place offer request failed:', error);
        throw error;
    }
};
export const getFavoritePlayers = async () => {
    try {
        const response = await apiInstance.get(`/user/favoriteplayers`, {
            headers,
        });

        return response.data;
    } catch (error) {
        console.error('Get favorite players request failed:', error);
        throw error;
    }
};

export const addFavoritePlayer = async (playerId, token) => {
    try {
        const response = await apiInstance.post(`/user/favoriteplayers/${playerId}`, {
            params: {
                token: token,
            },
            headers,

        });
        return response.data;
    } catch (error) {
        console.error('Add favorite player request failed:', error);
        throw error;
    }
};
export const removeFavoritePlayer = async (playerId, token) => {
    try {
        const response = await apiInstance.delete(`/user/favoriteplayers/${playerId}`, null, {
            params: {
                token: token,
            },
            headers,

        });
        return response.data;
    } catch (error) {
        console.error('Add favorite player request failed:', error);
        throw error;
    }
};

export const searchPlayers = async (playerName) => {
    try {
        const response = await apiInstance.get(`/competition/search`, {
            params: { t: playerName },
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Search players request failed:', error);
        throw error;
    }
};
export const removeOffer = async (leagueId, playerId, offerId) => {
    try {
        const response = await apiInstance.delete(`/leagues/${leagueId}/market/${playerId}/offers/${offerId}`, {

            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Remove offer request failed:', error);
        throw error;
    }
};

//--- LigaInsider---//
/*
export const getMarketWinner = async () => {
    return await fetchAndParseHTML('https://www.ligainsider.de/stats/kickbase/marktwerte/tag/gewinner/');
};

export const getMarketLooser = async () => {
    return await fetchAndParseHTML('https://www.ligainsider.de/stats/kickbase/marktwerte/tag/verlierer/');
};

export const getMarketAll = async () => {
    return await fetchAndParseHTML('https://www.ligainsider.de/stats/kickbase/marktwerte/gesamt/');
};
*/
async function fetchAndParseHTML(url) {
    try {
        // Use cors.sh proxy URL with the API key in the headers
        const response = await fetch(`https://proxy.cors.sh/${url}`, {
            headers: {
                'x-cors-api-key': 'temp_9e85c12760ef248eb1de7cb7a8d3697f'
            }
        });
        const html = await response.text();

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const players = [];
        const rows = doc.querySelectorAll('.leg_column_row');
        rows.forEach(row => {
            const player = {};
            const nameLink = row.querySelector('td:nth-child(2) a');

            if (nameLink) {
                player.name = nameLink.textContent;
                const href = nameLink.getAttribute('href');
                const idMatch = href.match(/_(\d+)\//); // Extract ID from the URL
                if (idMatch) {
                    player.ligainsiderId = idMatch[1];
                }
            }

            const teamLink = row.querySelector('td:nth-child(3) a');
            if (teamLink) {
                player.team = teamLink.textContent;
            }

            const position = row.querySelector('td:nth-child(4)');
            if (position) {
                player.position = position.textContent;
            }

            const points = row.querySelector('td:nth-child(5)');
            if (points) {
                player.points = points.textContent;
            }

            const marketValue = row.querySelector('td:nth-child(6)');
            if (marketValue) {
                player.marketValue = marketValue.textContent;
            }

            const growth = row.querySelector('td:nth-child(7)');
            if (growth) {
                player.growth = growth.textContent;
            }

            const difference = row.querySelector('td:nth-child(8)');
            if (difference) {
                player.difference = difference.textContent.split(' ')[0]; // Remove any trailing icons or text
            }

            players.push(player);
        });

        return players;
    } catch (error) {
        console.error('Error fetching or parsing HTML:', error);
        return [];
    }
}


// Helper function to parse LigaInsider data
export const parseLigaInsiderResponse = (data, isAll = false) => {
    return data.map(pl => {
        const marketValue = Number(isAll ? pl['marketValueChange'] : pl['marketValue']);
        const marketValueChange = Number(pl['marketValueChange']);

        return {
            name: pl['name'],
            marketValue,
            marketValueChange
        };
    });
};

export const getCurrentLeagueGift = async (leagueId) => {
    console.log("Called gift")
    if (leagueId === null && leaguesResponse === null) {
        try {
            const leaguesResponse = await getLeagues();
            const leagues = leaguesResponse.leagues;
            if (leagues.length > 0) {
                leagueId = leagues[0].id; // Use the first league's ID
            } else {
                throw new Error('No leagues found');
            }
        } catch (error) {
            console.error('Error fetching leagues:', error);
            throw error;
        }
    }


    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/currentgift`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Get current league gift request failed:', error);
        throw error;
    }
};

export const collectLeagueBonus = async (leagueId) => {
    try {
        const response = await apiInstance.post(`/leagues/${leagueId}/collectgift`, {}, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error('Collect league bonus request failed:', error);
        throw error;
    }
};


const ApiService = {

    login,
    resetPassword,
    getLeagueUserInfo,
    getUserMatchDayFeed,
    getLeagues,
    refreshChatToken,
    getPlayerInfo,
    getPlayerFeed,
    getPlayerPoints,
    getPlayerStats,
    searchCompetitionPlayers,
    removePlayerFromMarket,
    getMarketPlayers,
    addPlayerToMarket,
    acceptOffer,
    updatePrice,
    declineOffer,
    placeOffer,
    removeOffer,
    getPlayerData,
    getLeagueStats,
    collectLeagueBonus,
    getCurrentLeagueGift,
    getTeamRanking,
    getLeagueQuickStats
};

export default ApiService;


