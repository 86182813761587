import React, { useState, useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { searchPlayers } from './api'; // adjust the path
import PlayerCard from './PlayerCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import Modal from 'react-modal';

const PlayerSearch = ({ onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [players, setPlayers] = useState([]);
    const searchInputRef = useRef(null);

    const delayedSearch = useCallback(debounce((searchTerm) => {
        if (!searchTerm) {
            setPlayers([]);
            return;
        }
        searchPlayers(searchTerm)
            .then((playersResponse) => {
                setPlayers(playersResponse.p);
            })
            .catch((error) => {
                console.error('Failed to search players:', error);
            });
    }, 1000), []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        delayedSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearchTerm('');
        setPlayers([]);
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const normalizedPlayers = players.map(playerData => ({
        name: playerData.firstName + ' ' + playerData.lastName,
        position: playerData.position,
        number: playerData.number,
        averagePoints: playerData.averagePoints,
        totalPoints: playerData.totalPoints,
        marketValue: playerData.marketValue,
        marketValueIncrease: playerData.marketValueTrend,
        id: playerData.id,
        teamId: playerData.teamId,
        profileBig: playerData.profileBig,
    }));

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            overlayClassName="fixed inset-0 flex items-center justify-center z-50"
            className="bg-transparent relative"
        >
            <button
                className="absolute top-1 right-1 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                onClick={onClose}
                style={{ zIndex: 1001 }}
            >
                &times;
            </button>
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <motion.div
                    className="bg-transparent rounded-lg p-4 max-w-md w-full"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="relative">
                        <div className="search-bar-wrapper">
                            <div className="flex items-center bg-gray-700 text-white rounded-lg p-1 shadow-md">
                                <input
                                    ref={searchInputRef}
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    placeholder="Spieler suchen..."
                                    className="flex-grow p-2 rounded-l-lg bg-gray-600 text-white focus:outline-none"
                                />
                            </div>
                        </div>
                        <motion.div
                            className="results mt-4"
                            style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
                            initial={{ height: 0 }}
                            animate={{ height: players.length > 0 ? 'auto' : 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {Array.isArray(normalizedPlayers) && normalizedPlayers.map((player) => (
                                <PlayerCard player={player} key={player.id} />
                            ))}
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </Modal>
    );
};

export default PlayerSearch;