import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { playerStoreInstance } from './stores/PlayerStore';
import { getLeagueUserInfo } from './api'; // Make sure to import this function

const LeagueSelector = observer(({ onLeagueChange }) => {
    const [leagues, setLeagues] = useState([]);
    const [justFetchedLeagues, setJustFetchedLeagues] = useState(false);

    const fetchLeagues = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('Token not found');
            return;
        }

        try {
            const leagueInfo = await getLeagueUserInfo(playerStoreInstance.leagueId);

            if (!leagueInfo || !leagueInfo.leagues) {
                console.error('Invalid response from getLeagueUserInfo:', leagueInfo);
                return;
            }

            setLeagues(leagueInfo.leagues);
            setJustFetchedLeagues(true);
        } catch (error) {
            console.error('Error fetching leagues:', error);
        }
    };
    useEffect(() => {
        // Fetch leagues when the component mounts
        fetchLeagues();
    }, []);

    useEffect(() => {
        if (leagues.length > 0 && justFetchedLeagues) {
            const storedLeagueId = localStorage.getItem('selectedLeagueId');

            let selectedLeagueId;
            if (storedLeagueId && storedLeagueId !== '-' && leagues.some(league => league.id === storedLeagueId)) {
                selectedLeagueId = storedLeagueId;
            } else {
                selectedLeagueId = leagues[0].id;
            }

            playerStoreInstance.setLeagueId(selectedLeagueId);
            onLeagueChange(selectedLeagueId);
            setJustFetchedLeagues(false);
        }
    }, [justFetchedLeagues, leagues]);

    const handleLeagueChange = (event) => {
        const selectedLeagueId = event.target.value;
        playerStoreInstance.setLeagueId(selectedLeagueId);
        onLeagueChange(selectedLeagueId);
    }

    return (
        <div className="bg-gray-800 text-white p-4 rounded shadow-md">
            <select
                value={playerStoreInstance.leagueId}
                onChange={handleLeagueChange}
                className="w-full p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <option value="-" disabled>---</option>
                {leagues.map((league) => (
                    <option key={league.id} value={league.id}>
                        {league.name}
                    </option>
                ))}
            </select>
        </div>
    );
});

export default LeagueSelector;
