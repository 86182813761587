// CookieContext.js
import React, { useState, createContext, useContext } from 'react';

const CookieContext = createContext({
    cookiesAccepted: false,
    acceptCookies: () => { }
});

export const useCookieContext = () => useContext(CookieContext);

export const CookieProvider = ({ children }) => {
    const [cookiesAccepted, setCookiesAccepted] = useState(
        Boolean(localStorage.getItem('cookieAccepted'))
    );

    const acceptCookies = () => {
        localStorage.setItem('cookieAccepted', 'true');
        setCookiesAccepted(true);
    };

    return (
        <CookieContext.Provider value={{ cookiesAccepted, acceptCookies }}>
            {children}
        </CookieContext.Provider>
    );
};
