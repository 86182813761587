import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCircle, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { usePlayerStore } from './stores/PlayerStore';

const PlayerCard = React.memo(({ player, lastThreeGames, ...props }) => {
    console.log(`Rendering PlayerCard for ${player.name}, lastThreeGames:`, lastThreeGames);

    const renderPerformanceBars = (performanceData) => {
        if (!performanceData || !Array.isArray(performanceData)) {
            return null; // or return some default/placeholder content
        }

        return performanceData.map((data, index) => (
            <div key={index} className="flex items-center mb-1">
                <div className="w-20 text-xs">{data.label}</div>
                <div className="flex-grow bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${data.percentage}%` }}
                    ></div>
                </div>
                <div className="w-10 text-xs text-right">{data.value}</div>
            </div>
        ));
    };

    return (
        <div key={`${player.id}_${props.index}`} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform hover:scale-105 relative">
            {/* Player Image */}
            <img
                src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                onError={(e) => { e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`; }}
                alt={player.name}
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                className="rounded-full"
            />

            {/* Player Info */}
            <div className="flex-grow mx-3">
                <h5 className="font-bold">{player.name}</h5>
                <p className="text-sm">Durchschnitt: {player.averagePoints}</p>
                <p className="text-sm">Punkte: {player.totalPoints}</p>

                <p className="text-sm">Marktwert: {player.marketValue.toLocaleString()} €</p>
            </div>

            {/* Market Value Increase */}
            <div className="flex items-center">
                {player.marketValueIncrease === 0 ? (
                    <FontAwesomeIcon icon={faCircle} className="text-gray-400 ml-1" />
                ) : player.marketValueIncrease > 0 ? (
                    <FontAwesomeIcon icon={faArrowUp} className="text-green-600 ml-1" />
                ) : (
                    <FontAwesomeIcon icon={faArrowDown} className="text-red-600 ml-1" />
                )}
            </div>

            {/* Favorite Icon */}
            <FontAwesomeIcon
                icon={faStar}
                onClick={props.toggleFavorite}
                className={`${props.isFavorite ? 'text-yellow-400' : 'text-gray-400'} cursor-pointer ml-4`}
            />

            {/* Add performance bars */}
            <div className="mt-4">
                <h5 className="text-sm font-semibold text-gray-400 mb-2">Last 3 Games</h5>
                <div className="flex justify-between">
                    {renderPerformanceBars(lastThreeGames)}
                </div>
            </div>
        </div>
    );
});

export default PlayerCard;