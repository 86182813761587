import { playerStoreInstance } from './PlayerStore';
import {
    faFutbol,
    faHandsHelping,
    faExclamationTriangle,
    faShieldAlt,
    faChartLine,
    faCheck,
    faThumbsDown,
    faMagic,
    faEquals,
    faBandAid,
    faFire,
    faHandPaper,
    faBullseye
} from '@fortawesome/free-solid-svg-icons';


class Player {
    constructor(playerData, currentMatchDay, isMarketPlayer = false) {
        if (!playerData) {
            throw new Error('Player data must be provided');
        }

        this.currentMatchDay = currentMatchDay;
        this.id = playerData.id;
        this.firstName = playerData.firstName;
        this.lastName = playerData.lastName;
        this.name = `${playerData.firstName || ''} ${playerData.lastName || ''}`.trim() || 'Unknown';
        this.teamName = playerData.teamName || 'Unknown';
        this.teamSymbol = playerData.teamSymbol || 'Unknown';
        this.team = playerData.team;
        this.playerImage = playerData.profileBig;
        this.username = playerData.username;
        this.profile = playerData.profile;
        this.status = playerData.status;
        this._position = null;
        this.setPosition(playerData.position);
        this.number = playerData.number;
        this.averagePoints = playerData.averagePoints || 0;
        this.totalPoints = playerData.totalPoints || playerData.points || 0;
        this.marketValue = playerData.marketValue || playerData.m || 0;
        this.marketValueTrend = playerData.marketValueTrend || 0;
        this.userProfile = playerData.profileUrl;
        this.teamId = playerData.teamId;
        this.teamSymbol = playerData.teamCover;

        this.marketValueChange = playerData.leaguePlayer?.marketValueChange || 0;
        this.marketValueChangeScouting = playerData.marketValueChange || 0;
        this.owner = playerData.leaguePlayer?.userName;
        this.ownerPricePaid = playerData.leaguePlayer?.buyPrice;
        this.ownerEarning = playerData.leaguePlayer?.marketValueChange;
        this.buyPrice = playerData.leaguePlayer?.buyPrice || 0;
        this.nextOpponent = this.getNextOpponent(playerData.nm, playerData.teamId);
        this.marketValueLigaInsider = playerData.ligaInsider?.marketValue;
        this.marketWinnerRank = playerData.ligaInsider?.marketWinnerRank;
        this.marketLooserRank = playerData.ligaInsider?.marketLooserRank;
        this.marketAllRank = playerData.ligaInsider?.marketAllRank;
        
        this.marketValueIncrease = playerData.marketValueChange || 0;
        
        // If marketValueChange is not provided, calculate it from marketValues
        if (this.marketValueIncrease === 0 && playerData.marketValues && playerData.marketValues.length > 1) {
            const latestValue = playerData.marketValues[playerData.marketValues.length - 1].m;
            const previousValue = playerData.marketValues[playerData.marketValues.length - 2].m;
            this.marketValueIncrease = latestValue - previousValue;
        }
        
        this.marketValueChangePercent = Math.round((playerData.leaguePlayer?.marketValueChangePercent || 0) * 100) / 100;
        this.seasons = playerData.seasons?.map(season => ({
            id: season.seasonId,
            year: season.season,
            points: season.points,
            season: season.season,
            goals: season.goals,
            startMatches: season.startMatches,
            assists: season.assists,
            matches: season.matches,
            redCards: season.redCards || 0,
            yellowCards: season.yellowCards || 0
        })) || [];
        
        this.overallStats = this.calculateOverallStats();
        this.marketValueHigh = playerData.mvHigh;
        this.marketValueLow = playerData.mvLow;
        this.marketValueHighDate = playerData.mvHighDate;
        this.marketValueLowDate = playerData.mvLowDate;
        this.userProfileUrl = playerData.userProfileUrl;

        this.isMarketPlayer = isMarketPlayer;
        
        if (isMarketPlayer) {
            // Market player specific properties
            this.expire = playerData.expiry;
            this.offers = playerData.offers;
            this.firstOfferPrice = this.offers?.[0]?.price || 0;
        } else {
            // Team player specific properties
            this.buyDate = playerData.leaguePlayer?.buyDate;
            this.matchesTotal = playerData.leaguePlayer?.matchesTotal || 0;
        }

        this.traits = [];
        this.currentSeasonStats = null;
        
        // Don't calculate traits immediately for market players
        if (!isMarketPlayer) {
            this.calculateTraits();
        }

        this.calculateDailyValueIncrease = this.calculateDailyValueIncrease.bind(this);
        this.getTraits = this.getTraits.bind(this);
        this.marketValues = [];
        this.isHistoricalDataFetched = false;
        this.dataFetchError = null;

        this.playerStore = playerStoreInstance;
        this.stats = null;
        this.isStatsFetched = false;
    }
    async fetchStats() {
        if (!this.playerStore) {
            console.error('PlayerStore not available');
            return;
        }
        const stats = await this.playerStore.getPlayerStats(this.playerStore.leagueId, this.id);
        if (stats.error) {
            console.error(`Error fetching stats for player ${this.id}:`, stats.error);
            // Handle the error as needed, maybe set a flag in the Player instance
            this.statsError = stats.error;
        } else {
            // Process the stats as normal
            this.processStats(stats);
        }
    }

    updatePropertiesFromStats(stats) {
        // Update player properties based on fetched stats
        if (stats.marketValues) {
            this.marketValues = stats.marketValues.reverse();
        }
        // Add other property updates as needed
    }

    calculateMVTrend() {
        return this.marketValueHigh - this.marketValueLow;
    }

    getFirstOfferPrice() {
        return this.firstOfferPrice;
    }

    async fetchAndSetPerformances() {
        try {
            console.log(`Fetching performances for player ${this.id}`);
            if (!this.playerStore || typeof this.playerStore.getPlayerStats !== 'function') {
                throw new Error('PlayerStore or getPlayerStats method is not properly initialized');
            }

            const stats = await this.playerStore.getPlayerStats(this.playerStore.leagueId, this.id);
            console.log(`Full API response for player ${this.id}:`, JSON.stringify(stats, null, 2));

            if (!stats || !stats.points || stats.points.length === 0) {
                console.warn(`No performance data found for player ${this.id}`);
                this.performances = [];
                return;
            }

            this.performances = stats.points.map(point => ({
                d: point.d, // matchday
                p: point.p, // points
                sp: point.sp, // spielzeit (playing time)
                g: point.g, // goals
                a: point.a, // assists
                h: point.h // home (1) or away (0)
            }));

            console.log(`Set performances for player ${this.id}:`, this.performances);
        } catch (error) {
            console.error('Failed to fetch performances for player:', error);
            this.performances = [];
        }
    }

    async getLastThreeGamePerformances() {
        console.log(`Getting last three game performances for player ${this.id}`);
        if (!this.performances) {
            console.log(`Performances not loaded for player ${this.id}, fetching now...`);
            await this.fetchAndSetPerformances();
        }

        if (!this.performances || this.performances.length === 0) {
            console.warn(`No performances available for player ${this.id}`);
            return [];
        }

        console.log(`Raw performances for player ${this.id}:`, this.performances);

        // Sort the performances by matchday (d) in descending order
        const sortedPerformances = [...this.performances].sort((a, b) => b.d - a.d);
        console.log(`Sorted performances for player ${this.id}:`, sortedPerformances);

        // Take the last three games
        const lastThree = sortedPerformances.slice(0, 3);

        console.log(`Last three game performances for player ${this.id}:`, lastThree);
        return lastThree;
    }

    async fetchAndSetCurrentSeasonStats() {
        try {
            if (!this.playerStore || typeof this.playerStore.getPlayerStats !== 'function') {
                throw new Error('PlayerStore or getPlayerStats method is not properly initialized');
            }

            const stats = await this.playerStore.getPlayerStats(this.playerStore.leagueId, this.id);
            if (!stats || !stats.s || stats.s.length === 0) {
                console.warn(`No stats data found for player ${this.id}`);
                return null;
            }

            // Assuming the latest season is the last one in the array
            const latestSeason = stats.s[stats.s.length - 1];

            this.currentSeasonStats = {
                seasonId: latestSeason.i,
                season: latestSeason.t,
                totalPoints: latestSeason.p,
                matchesPlayed: latestSeason.mp,
                matchesStarted: latestSeason.ms,
                games: Array.isArray(latestSeason.m) ? latestSeason.m.map(game => ({
                    matchDay: game.d,
                    points: game.p,
                    secondsPlayed: game.sp,
                    started: game.ms,
                    team1Id: game.t1i,
                    team1Score: game.t1s,
                    team2Id: game.t2i,
                    team2Score: game.t2s,
                    goals: game.g,
                    assists: game.a,
                    yellowCards: game.y,
                    redCards: game.r,
                    homeOrAway: game.h === 1 ? 'Home' : 'Away'
                })) : []
            };

            if (this.currentSeasonStats.games.length > 0) {
                this.processCurrentSeasonGames();
                this.calculateTraits();
                this.marketValueIncrease = this.calculateDailyValueIncrease();
            } else {
                console.warn(`No games data found for player ${this.id}`);
            }

            return this.currentSeasonStats;
        } catch (error) {
            console.error('Failed to fetch current season stats for player:', error);
            return null;
        }
    }

    processCurrentSeasonGames() {
        if (!this.currentSeasonStats || !this.currentSeasonStats.games) {
            return;
        }

        const games = this.currentSeasonStats.games;
        this.currentSeasonStats.totalGoals = games.reduce((acc, game) => acc + game.goals, 0);
        this.currentSeasonStats.totalAssists = games.reduce((acc, game) => acc + game.assists, 0);
        this.currentSeasonStats.isPerformingWell = this.isPerformingWell();
    }

    isPerformingWell() {
        if (!this.currentSeasonStats || !this.currentSeasonStats.games || this.currentSeasonStats.games.length === 0) {
            return false; // Not enough data to determine performance
        }

        const recentGames = this.currentSeasonStats.games.slice(-3); // Consider last 3 games
        if (recentGames.length === 0) return false;

        const recentAverage = recentGames.reduce((sum, game) => sum + game.points, 0) / recentGames.length;
        const overallAverage = this.averagePoints;

        // Calculate the performance difference
        const performanceDifference = recentAverage - overallAverage;

        // Calculate a performance score
        let performanceScore;
        if (performanceDifference > 20) {
            performanceScore = 2; // Significantly better
        } else if (performanceDifference > 10) {
            performanceScore = 1.5; // Noticeably better
        } else if (performanceDifference > 0) {
            performanceScore = 1.2; // Slightly better
        } else if (performanceDifference > -10) {
            performanceScore = 1; // About average
        } else if (performanceDifference > -20) {
            performanceScore = 0.8; // Slightly worse
        } else {
            performanceScore = 0.6; // Significantly worse
        }


        return performanceScore;
    }

    calculateCurrentSeasonAverage() {
        if (this.currentSeasonStats && this.currentSeasonStats.matchesPlayed > 0) {
            return this.currentSeasonStats.totalPoints / this.currentSeasonStats.matchesPlayed;
        }
        return 0;
    }

    calculateHistoricalAverage() {
        const totalHistoricalPoints = this.seasons.reduce((acc, season) => acc + season.points, 0);
        const totalHistoricalMatches = this.seasons.reduce((acc, season) => acc + season.matches, 0);

        if (totalHistoricalMatches > 0) {
            return totalHistoricalPoints / totalHistoricalMatches;
        }
        return 0;
    }

    analyzeRecentPerformance() {
        const recentGames = this.currentSeasonStats.games.slice(-2);
        if (recentGames.length === 2 && recentGames.every(game => game.points > 100)) {
            return 'excellent';
        }
        const recentAverage = recentGames.reduce((acc, game) => acc + game.points, 0) / recentGames.length;
        const currentSeasonAverage = this.calculateCurrentSeasonAverage();

        if (recentAverage > currentSeasonAverage) {
            return 'improving';
        } else if (recentAverage < currentSeasonAverage) {
            return 'declining';
        } else {
            return 'stable';
        }
    }

    async calculateFitPercentage(playerStore) {
        let fitScore = 50; // Start with a base score of 50
        const maxScore = 100;

        // Daily value increase (max 20 points, min -20 points)
        const dailyIncrease = this.marketValueIncrease || 0;
        const increaseScore = Math.max(Math.min((dailyIncrease / 10000) * 20, 20), -20);
        fitScore += increaseScore;

        // Future value trend (max 10 points, min -10 points)
        const futureValueIncrease = await this.calculateFutureValueIncrease(7, playerStore.leagueId);
        let futureTrendScore = 0;
        if (futureValueIncrease && !isNaN(futureValueIncrease.trendScore)) {
            futureTrendScore = Math.max(Math.min(futureValueIncrease.trendScore / 10, 10), -10);
            fitScore += futureTrendScore;
        }

        // Performance factor (max 10 points, min -10 points)
        const performanceFactor = this.calculatePerformanceFactor();
        const performanceScore = (performanceFactor - 1) * 10;
        fitScore += performanceScore;

        // Comparison with team players (max 10 points, min -10 points)
        const comparisonResult = playerStore.compareWithTeamPlayers(this);
        const comparisonScore = comparisonResult.isBest 
            ? 10 
            : Math.max(Math.min(comparisonResult.difference, 10), -10);
        fitScore += comparisonScore;

        // Financial fit (max 10 points, min -10 points)
        const investmentLeft = playerStore.investmentLeft || 1;
        const financialFitScore = Math.max(10 - ((this.marketValue / investmentLeft) * 20), -10);
        fitScore += financialFitScore;

        // Position availability (max 10 points, min -10 points)
        const positionCounts = playerStore.getPositionCounts();
        const maxPlayers = { 'Torwart': 1, 'Verteidiger': 5, 'Mittelfeldspieler': 5, 'Stürmer': 3 };
        const mappedPosition = this.mapPosition(this.position);
        const availabilityScore = Math.max(Math.min(10 * (1 - ((positionCounts[mappedPosition] || 0) / (maxPlayers[mappedPosition] || 1))), 10), -10);
        fitScore += availabilityScore;

        // Games played calculation
        const gamesPlayed = this.currentSeasonStats?.gamesPlayed || 0;
        const totalGames = this.currentSeasonStats?.totalGames || 0;
        const participationRate = totalGames > 0 ? gamesPlayed / totalGames : 0;
        const participationScore = (participationRate - 0.5) * 20; // Scale from -10 to 10
        fitScore += participationScore;

        const finalFitScore = Math.round(Math.max(0, Math.min(fitScore, maxScore)));

        // Determine form based on performance factor
        let form;
        if (performanceFactor >= 1.5) form = "Ausgezeichnete Form";
        else if (performanceFactor >= 1.2) form = "Gute Form";
        else if (performanceFactor >= 0.8) form = "Normale Form";
        else if (performanceFactor >= 0.5) form = "Schlechte Form";
        else form = "Sehr schlechte Form";

        const estimatedSalePrice = playerStore.estimateSalePrice(this);

        return {
            fitPercentage: finalFitScore,
            form: form,
            components: {
                increaseScore,
                futureTrendScore,
                performanceScore,
                comparisonScore,
                financialFitScore,
                availabilityScore,
                participationScore
            },
            futureValueIncrease,
            comparisonResult,
            financialPercentage: (this.marketValue / investmentLeft) * 100,
            positionCounts,
            maxPlayers,
            teamPlayersCount: playerStore.getTeamPlayersCount(this.teamId),
            gamesPlayed,
            totalGames,
            estimatedSalePrice
        };
    }
    
    calculateTraits() {
        const games = this.currentSeasonStats?.games || [];
        
        if (games.length > 0) {
            this.traits = [];

            this.addTraitBasedOnGoals(games);
            this.addTraitBasedOnAssists(games);
            this.addTraitBasedOnDiscipline(games);
            this.addTraitForConsistency(games);
            if (this.position === 'Verteidiger') {
                this.addTraitBasedOnDefense(games);
            }
            this.addTraitBasedOnMarketValue();
            this.addTraitBasedOnStarts(games, this.currentMatchDay);
            this.addFormTrait(games);

            // New traits
            this.addGlasknoechelTrait(games);
            this.addJokerTrait(games);
            this.addElfmeterkillerTrait(games);
            this.addFreistosskuenstlerTrait(games);
            this.addTorvorlagegeberTrait(games);
        } else if (this.isMarketPlayer) {
            // For market players, add traits based on available data
            this.addTraitBasedOnMarketValue();
            // Add more trait calculations that don't require game data
        } else {
          //  console.warn(`No games data available for ${this.name}. Traits calculation skipped.`);
        }
    }

    addTraitBasedOnGoals(games) {
        const totalGoals = games.reduce((acc, game) => acc + game.goals, 0);
        if (totalGoals >= 13) {
            this.traits.push({
                icon: faFutbol,
                description: `Torjäger: ${totalGoals} Tore`
            });
        }
    }

    addTraitBasedOnAssists(games) {
        const totalAssists = games.reduce((acc, game) => acc + game.assists, 0);
        if (totalAssists >= 8) {
            this.traits.push({
                icon: faHandsHelping,
                description: `Spielmacher: ${totalAssists} Assists`
            });
        }
    }

    addTraitForConsistency(games) {
        const totalPoints = games.reduce((acc, game) => acc + game.points, 0);
        const averagePoints = totalPoints / games.length;
        const variance = games.reduce((acc, game) => acc + Math.pow(game.points - averagePoints, 2), 0) / games.length;

        if (variance < 15 && games.length > 5) {
            this.traits.push({
                icon: faEquals,
                description: `Konstanz-König: Geringe Punkteschwankungen`
            });
        }
    }

    addTraitBasedOnDiscipline(games) {
        const yellowCards = games.reduce((acc, game) => acc + (game.yellowCards || 0), 0);
        if (yellowCards > 2) {
            this.traits.push({
                icon: faExclamationTriangle,
                description: `Gelbe Karten: ${yellowCards} Verwarnungen`
            });
        }
    }

    addTraitBasedOnDefense(games) {
        const defensiveActions = games.reduce((acc, game) => acc + (game.tackles || 0), 0);
        if (defensiveActions >= 5) {
            this.traits.push({
                icon: faShieldAlt,
                description: `Defensivspezialist: ${defensiveActions} erfolgreiche Abwehraktionen`
            });
        }
    }

    addTraitBasedOnMarketValue() {
        if (this.marketValueChangePercent > 10) {
            this.traits.push({
                icon: faChartLine,
                description: `Marktwertsteigerung: +${this.marketValueChangePercent.toFixed(2)}%`
            });
        }
    }

    addTraitBasedOnStarts(games, totalMatchDays) {
        const totalGamesPlayed = games.length;
        const percentageOfGamesPlayed = (totalGamesPlayed / this.currentMatchDay) * 100;
        const averageSecondsPlayed = games.reduce((acc, game) => acc + game.secondsPlayed, 0) / totalGamesPlayed;
        if (percentageOfGamesPlayed >= 85 && averageSecondsPlayed >= (5150 * 0.85)) {
            this.traits.push({
                icon: faCheck,
                description: `Stammkraft: Hat bereits ${totalGamesPlayed} Spiele gemacht`
            });
        }
    }

    addFormTrait(games) {
        if (games.length < 5) return; // Not enough recent games to determine form

        const recentGames = games.slice(-5);
        const averageRecentPoints = recentGames.reduce((sum, game) => sum + game.points, 0) / recentGames.length;
        const overallAveragePoints = this.averagePoints;

        // Calculate standard deviation of player's points over the season
        const pointsDeviation = Math.sqrt(
            games.reduce((sum, game) => sum + Math.pow(game.points - overallAveragePoints, 2), 0) / games.length
        );

        // Define thresholds for exceptional form (2 standard deviations from mean)
        const topFormThreshold = overallAveragePoints + (2 * pointsDeviation);
        const formTiefThreshold = overallAveragePoints - (2 * pointsDeviation);

        if (averageRecentPoints > topFormThreshold) {
            this.traits.push({
                icon: faFire,
                description: 'Topform: Außergewöhnlich starke Leistungen'
            });
        } else if (averageRecentPoints < formTiefThreshold) {
            this.traits.push({
                icon: faThumbsDown,
                description: 'Formtief: Deutlich unter gewohnter Leistung'
            });
        }
        // If neither condition is met, no form trait is added
    }

    addGlasknoechelTrait(games) {
        const missedGames = this.currentMatchDay - games.length;
        if (missedGames > 5) {
            this.traits.push({
                icon: faBandAid,
                description: `Glasknöchel: ${missedGames} verpasste Spiele`
            });
        }
    }

    addJokerTrait(games) {
        const subAppearances = games.filter(game => !game.started && game.secondsPlayed > 0);
        const subGoals = subAppearances.reduce((sum, game) => sum + game.goals, 0);
        const subAssists = subAppearances.reduce((sum, game) => sum + game.assists, 0);

        if (subGoals + subAssists >= 5) {
            this.traits.push({ 
                icon: faMagic,
                description: `Joker: ${subGoals + subAssists} Torbeteiligungen als Einwechselspieler`
            });
        }
    }

    addElfmeterkillerTrait(games) {
        // Assuming we have data for penalties scored or saved
        const penaltiesScored = games.reduce((sum, game) => sum + (game.penaltiesScored || 0), 0);
        const penaltiesSaved = games.reduce((sum, game) => sum + (game.penaltiesSaved || 0), 0);

        if (this.position === 'Torwart' && penaltiesSaved >= 3) {
            this.traits.push({
                icon: faHandPaper,
                description: `Elfmeterkiller: ${penaltiesSaved} gehaltene Elfmeter`
            });
        } else if (penaltiesScored >= 5) {
            this.traits.push({
                icon: faBullseye,
                description: `Elfmeterkiller: ${penaltiesScored} verwandelte Elfmeter`
            });
        }
    }

    addFreistosskuenstlerTrait(games) {
        // Assuming we have data for free kicks scored
        const freeKicksScored = games.reduce((sum, game) => sum + (game.freeKicksScored || 0), 0);

        if (freeKicksScored >= 3) {
            this.traits.push({
                icon: faFutbol,
                description: `Freistoßkünstler: ${freeKicksScored} Freistoßtore`
            });
        }
    }

    addTorvorlagegeberTrait(games) {
        const totalAssists = games.reduce((sum, game) => sum + game.assists, 0);

        if (totalAssists >= 10) {
            this.traits.push({
                icon: faHandsHelping,
                description: `Torvorlagengeber: ${totalAssists} Assists`
            });
        }
    }

    getNextOpponent(matches, teamId) {
        if (!matches || matches.length === 0) return null;

        const nextMatch = matches[0];

        if (nextMatch.t1i === teamId) {
            return {
                teamId: nextMatch.t2i,
                teamName: nextMatch.t2n,
                teamShortName: nextMatch.t2y,
                matchDate: nextMatch.d,
                homeOrAway: "Heimspiel gegen: "
            };
        } else {
            return {
                teamId: nextMatch.t1i,
                teamName: nextMatch.t1n,
                teamShortName: nextMatch.t1y,
                matchDate: nextMatch.d,
                homeOrAway: "Auswärts gegen: "
            };
        }
    }

    calculateValueChange() {
        return this.marketValue - this.buyPrice;
    }

    calculateOverallStats() {
        const totalSeasons = this.seasons.length;
        if (totalSeasons === 0) return null;

        const overall = this.seasons.reduce((acc, season) => {
            acc.goals += season.goals;
            acc.matches += season.matches;
            acc.assists += season.assists;
            acc.startMatches += season.startMatches;
            acc.yellowCards += season.yellowCards;
            acc.redCards += season.redCards;
            acc.secondsPlayed += season.secondsPlayed;
            acc.defensivePoints += season.defensivePoints;
            acc.offensivePoints += season.offensivePoints;
            acc.generalPoints += season.generalPoints;
            acc.teamPoints += season.teamPoints;
            acc.goalKeeperPoints += season.goalKeeperPoints;
            return acc;
        }, {
            goals: 0,
            matches: 0,
            assists: 0,
            startMatches: 0,
            yellowCards: 0,
            redCards: 0,
            secondsPlayed: 0,
            defensivePoints: 0,
            offensivePoints: 0,
            generalPoints: 0,
            teamPoints: 0,
            goalKeeperPoints: 0
        });

        overall.goalsPerMatch = overall.goals / overall.matches;
        overall.assistsPerMatch = overall.assists / overall.matches;
        overall.minutesPlayed = overall.secondsPlayed / 60;
        overall.defensiveAverage = this.calculateAverage(overall.defensivePoints, totalSeasons);
        overall.offensiveAverage = this.calculateAverage(overall.offensivePoints, totalSeasons);
        overall.generalAverage = this.calculateAverage(overall.generalPoints, totalSeasons);
        overall.teamAverage = this.calculateAverage(overall.teamPoints, totalSeasons);
        overall.goalKeeperAverage = this.calculateAverage(overall.goalKeeperPoints, totalSeasons);

        return overall;
    }

    calculateAverage(points, seasons) {
        return seasons > 0 ? points / seasons : 0;
    }

    setPosition(pos) {
        this._position = this.mapPosition(pos);
    }

    get position() {
        return this._position;
    }

    mapPosition(pos) {
        const positions = {
            'GK': 'Torwart',
            'DEF': 'Verteidiger',
            'MID': 'Mittelfeldspieler',
            'FWD': 'Stürmer',
            1: 'Torwart',
            2: 'Verteidiger',
            3: 'Mittelfeldspieler',
            4: 'Stürmer'
        };
        // Ensure we're always returning a string
        return positions[pos] || 'Unbekannt';
    }

    getTraits() {
        if (!this.traits) {
            this.calculateTraits();
        }
        return this.traits || [];
    }

    async fetchHistoricalMarketValues(leagueId) {
        if (this.isHistoricalDataFetched) return;

        try {
            const playerStats = await this.playerStore.getPlayerStats(leagueId, this.id);
            if (playerStats && playerStats.marketValues) {
                this.marketValues = playerStats.marketValues.reverse();
                this.isHistoricalDataFetched = true;
            } else {
                throw new Error('No market values found');
            }
        } catch (error) {
            console.error("Error fetching player stats:", error);
            this.dataFetchError = error;
            // Set a basic market value history using the current market value
            this.marketValues = [
                { d: new Date().toISOString(), m: this.marketValue },
                { d: new Date(Date.now() - 86400000).toISOString(), m: this.marketValue }
            ];
        } finally {
            this.isHistoricalDataFetched = true;
        }
    }

    getMarketValueAtDate(date) {
        if (!this.marketValues) return null;
        
        const targetDate = new Date(date);
        for (let i = 0; i < this.marketValues.length; i++) {
            const currentDate = new Date(this.marketValues[i].d);
            if (currentDate <= targetDate) {
                return this.marketValues[i].m;
            }
        }
        return null;
    }

    async calculateFutureValueIncrease(daysIntoFuture = 7, leagueId) {

        
        if (!this.isHistoricalDataFetched) {
            await this.fetchHistoricalMarketValues(leagueId);
        }


        if (!this.marketValues || this.marketValues.length < 7) {
            return this.estimateValueTrend(daysIntoFuture);
        }

        // Calculate recent trend
        const recentTrend = this.calculateRecentTrend();

        // Factor in performance and status
        let performanceFactor = this.calculatePerformanceFactor();

        // Calculate base daily change
        let estimatedDailyChange = recentTrend * performanceFactor;

        // Apply volatility based on recent changes
        const volatility = this.calculateVolatility();
        estimatedDailyChange *= (1 + (Math.random() - 0.5) * volatility);

        // Limit extreme changes
        const maxDailyChangePercentage = 0.1; // 10% max daily change
        const maxDailyChange = this.marketValue * maxDailyChangePercentage;
        estimatedDailyChange = Math.max(Math.min(estimatedDailyChange, maxDailyChange), -maxDailyChange);


        // Project future value
        let forecastedValue = this.marketValue;
        for (let i = 0; i < daysIntoFuture; i++) {
            forecastedValue += estimatedDailyChange;
            // Gradually reduce the impact of the daily change
            estimatedDailyChange *= 0.95;
        }

        const totalChange = forecastedValue - this.marketValue;
        const percentageChange = (totalChange / this.marketValue) * 100;
        const trendScore = Math.max(Math.min(percentageChange * 2, 100), -100);

        return {
            trendScore: Math.round(trendScore),
            estimatedDailyChange: Math.round(estimatedDailyChange),
            forecastedValue: Math.round(forecastedValue)
        };
    }

    calculateRecentTrend() {
        const recentValues = this.marketValues.slice(0, 7); // Last 7 days
        if (recentValues.length < 2) return 0;
        
        let totalChange = 0;
        for (let i = 0; i < recentValues.length - 1; i++) {
            totalChange += recentValues[i].m - recentValues[i + 1].m;
        }
        return totalChange / (recentValues.length - 1);
    }

    calculatePerformanceFactor() {
        let factor = 1;

        // Factor in recent performance
        if (this.isPerformingWell()) factor *= 1.2;

        // Adjust for player status
        if (this.status === 1) factor *= 0.7; // Injured
        if (this.status === 2) factor *= 0.9; // Slightly injured

        // Additional factors
        if (this.hasRedCard) factor *= 0.8;
        if (this.hasYellowCards >= 4) factor *= 0.9; // Risk of suspension

        // Cap the factor to prevent extreme values
        factor = Math.max(0.5, Math.min(factor, 2));



        return factor;
    }

    calculateVolatility() {
        const recentValues = this.marketValues.slice(0, 14); // Last 14 days
        if (recentValues.length < 2) return 0.05; // Default volatility
        
        let changes = [];
        for (let i = 0; i < recentValues.length - 1; i++) {
            changes.push(Math.abs((recentValues[i].m - recentValues[i + 1].m) / recentValues[i + 1].m));
        }
        return Math.min(Math.max(changes.reduce((a, b) => a + b) / changes.length, 0.01), 0.2);
    }

    estimateValueTrend(daysIntoFuture) {
        // Fallback method when insufficient historical data is available
        const estimatedDailyChange = this.marketValue * 0.01 * (Math.random() - 0.5); // ±0.5% daily change
        const forecastedValue = this.marketValue + (estimatedDailyChange * daysIntoFuture);
        const percentageChange = ((forecastedValue - this.marketValue) / this.marketValue) * 100;
        const trendScore = Math.max(Math.min(percentageChange * 2, 100), -100);

        return {
            trendScore: Math.round(trendScore),
            estimatedDailyChange: Math.round(estimatedDailyChange),
            forecastedValue: Math.round(forecastedValue)
        };
    }

    calculateParticipationRate() {
        if (!this.currentSeasonStats || this.currentSeasonStats.totalGames === 0) {
            return 0;
        }
        return this.currentSeasonStats.gamesPlayed / this.currentSeasonStats.totalGames;
    }



    calculateDailyValueIncrease() {
        if (this.marketValueHistory && this.marketValueHistory.length > 1) {
            const latestValue = this.marketValueHistory[0].m;
            const oldestValue = this.marketValueHistory[this.marketValueHistory.length - 1].m;
            const daysDifference = (new Date(this.marketValueHistory[0].d) - new Date(this.marketValueHistory[this.marketValueHistory.length - 1].d)) / (1000 * 60 * 60 * 24);
            
            if (daysDifference > 0) {
                return (latestValue - oldestValue) / daysDifference;
            }
        }
        return null;
    }
    calculateMarketValueTrend(days = 7) {
        if (!this.marketValues || this.marketValues.length < 2) return { trend: 0, values: [] };

        // Ensure the array is sorted with the most recent date last
        const sortedValues = [...this.marketValues].sort((a, b) => new Date(a.d) - new Date(b.d));

        const relevantValues = sortedValues.slice(-days);
        const oldestValue = relevantValues[0].m;
        const currentValue = this.marketValue || relevantValues[relevantValues.length - 1].m;
        const trend = currentValue - oldestValue;

        return {
            trend,
            values: relevantValues
        };
    }
}

export default Player;
