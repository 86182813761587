export function getPlayerTraits(player) {
    if (typeof player.getTraits === 'function') {
        return player.getTraits();
    }
    return player.traits || [];
}

export function getPlayerPosition(player) {
    if (typeof player.position === 'function') {
        return player.position();
    }
    if (typeof player.position === 'string') {
        return player.position;
    }
    return mapPosition(player.position);
}

function mapPosition(pos) {
    const positions = {
        'GK': 'Torwart',
        'DEF': 'Verteidiger',
        'MID': 'Mittelfeldspieler',
        'FWD': 'Stürmer',
        1: 'Torwart',
        2: 'Verteidiger',
        3: 'Mittelfeldspieler',
        4: 'Stürmer'
    };
    return positions[pos] || 'Unbekannt';
}

export function calculateMarketValueTrend(player, days = 7) {
    // Implement the trend calculation logic here
    // This is just a placeholder implementation
    const trend = player.marketValueTrend || 0;
    const values = []; // You might need to calculate this based on historical data

    return { trend, values };
}

export const knownPlayerIds = [4,
            6187,
            6288, 6304,
            8,
            6312,
            6314,
            6318,
            6171, 6172, 6173, 6174, 6175, 6177,
            6319,
            173, 237, 285, 304, 336, 357,
            36,
            43, 44,
            46,
            49,
            51,
            54,
            58,
            60,
            78,
            620,
            623, 624, 651, 655, 660,
            383,
            387, 392, 399,
            429,
            1229, 1246, 1249,
            663,
            668,
            699, 734, 737,
            450, 461, 477, 493, 497, 513,
            82, 89, 96, 97, 118, 157, 163, 168,
            1253,
            755,
            841,
            849, 1138, 1158, 1183,
            524, 547, 550, 573, 577, 579,
            479,
            608, 619,
            1581,
            1590, 1618, 1622,
            1257,
            1266,
            1298,
            1639,
            1645,
            1308, 1330,
            1333,
            1653,
            1655,
            1192, 1217, 1222, 1223, 1225, 1226,
            1761,
            1774,
            1785,
            1782,
            1804,
            1659, 1664,
            1809,
            1811,
            1815,
            1387, 1405, 1464, 1514, 1527, 1540, 1544, 1553, 1561, 1571, 1580,
            1671,
            1685,
            1686,
            1918,
            1920,
            1929, 1954,
            1961,
            1823,
            1971,
            1829,
            1975,
            1856,
            1858,
            1862,
            1687, 1689, 1703, 1704, 1725, 1726, 1739, 1758,
            1865,
            2084,
            2091,
            2092, 2120, 2124, 2125,
            1990,
            1991,
            1996,
            2005,
            2030,
            1868, 1871, 1873, 1883, 1896, 1903, 1910,
            2263,
            2273,
            2274,
            2141,
            2144,
            2275,
            2278,
            2148, 2176,
            2279,
            2287,
            2172,
            2195,
            2038, 2037, 2047, 2053, 2065, 2068, 2069, 2074, 2079,
            2197,
            2373,
            2375,
            2288,
            2294,
            2300,
            2199,
            2201,
            2302,
            2304,
            2309,
            2214, 2229,
            2232,
            2378,
            2383,
            2321,
            2335,
            2237, 2255,
            2393,
            2395,
            2400,
            2399,
            2426,
            2430,
            2439,
            2450,
            2343,
            2261,
            2349,
            2515,
            2358,
            2521,
            2522,
            2366,
            2365,
            2526,
            2646,
            2532,
            2533,
            2536,
            2453,
            2455,
            2460,
            2463,
            2476,
            2367,
            2489,
            2491,
            2649,
            2655,
            2539,
            2494,
            2662,
            2546,
            2551,
            2552,
            2553,
            2561,
            2572,
            2582,
            2584,
            2496,
            2664,
            2665,
            2681,
            2596,
            2630,
            2746,
            2639,
            2643,
            2749,
            2758,
            2690,
            2692,
            2764,
            2765,
            2694,
            2702,
            2766,
            2714,
            2776,
            2783,
            2792,
            2718,
            2717,
            2722,
            2724,
            2644,
            2725,
            2727, 2735,
            2736,
            2850,
            2737,
            2852,
            2854,
            2863,
            2791,
            2866,
            2883,
            2793,
            2795,
            2885,
            2799,
            2804,
            2887,
            2893,
            2809,
            2894,
            2897,
            2915,
            2814, 2819, 2822,
            2918,
            2832,
            2835,
            2842,
            2987,
            2988,
            2990,
            2994,
            3001,
            3004,
            3011,
            3087,
            3090,
            3089,
            3105,
            3108,
            3013, 3017, 3019, 3025, 3027,
            2927,
            2939,
            2941,
            2944,
            2946,
            3029, 3030,
            2948,
            3041,
            2977, 2979,
            3120,
            3124,
            3125,
            3128,
            3129,
            3130,
            3132,
            3050, 3063, 3068,
            3143, 3151, 3160,
            3233,
            3232,
            3069, 3079, 3085,
            3204,
            3208,
            3219,
            3225,
            3228,
            3230,
            3236,
            3238,
            3345,
            3362,
            3369,
            3242,
            3167,
            3190,
            3245,
            3397,
            3187,
            3454,
            3457,
            3249,
            3459,
            3470,
            3250, 3253,
            3191, 3193, 3196,
            3471,
            3557,
            3282,
            3473,
            3476,
            3289,
            3306,
            3475,
            3559,
            3561,
            3562,
            4252,
            4258,
            3477, 3479, 3481,
            3329, 3339, 3344,
            4253,
            3482,
            4266,
            4265,
            4286, 4296, 4303,
            3483,
            3500,
            4421,
            4460,
            4494,
            4520,
            4545,
            4546,
            4555, 4557, 4576,
            4314, 4316, 4317,
            3515, 3548, 3558,
            4318, 4333,
            4573, 4577, 4580,
            4352,
            4406,
            4617,
            4623,
            4630,
            4633,
            4654,
            4656,
            4579, 4581, 4588,
            4418,
            4419,
            4662, 6060,
            4590, 4596,
            6066, 6080,
            4598,
            6085, 6088,
            4600, 4610, 4611,
            6176,
            6179, 6180,
            6094,
            6111, 6134,
            6181,
            6182,
            6183,
            6171, 6172,
            6184, 6185, 6186, 6187, 6288, 6304,
            6312, 6314, 6318,
            6173, 6174, 6175, 6177,
            6319, 6518];
;

export function calculateFitPercentage(player, playerStore) {
    // Implement the fit percentage calculation here
    // This should mimic the logic from the Player class
    // You may need to adjust this based on your specific requirements
    let fitScore = 0;
    // ... implement fit score calculation ...
    return Math.min(Math.round(fitScore), 100);
}

export function getKnownPlayerIds() {
    return knownPlayerIds;
}