import { makeAutoObservable, runInAction } from 'mobx';
import { getUserInfo, getPlayerInfo, getMarketPlayers, getLeagueStats,getLeagueUserInfo, getPlayerStats, getFavoritePlayers, addFavoritePlayer, removeFavoritePlayer, getLeagueFeed, getPlayerValueAtDate, getPlayerPoints } from '../api';

import Player from './Player';
import { getKnownPlayerIds } from '../utils/playerUtils';
import { dbManager } from '../utils/DatabaseManager';
import { openDB } from 'idb';
import React from 'react';

class PlayerStore {
    players = [];
    _leagueId = null;
    leagues = [];
    leaguesLoading = true;
    marketPlayers = [];
    expire = 0;
    userInfo = null;
    currentLeagueId = null;
    favoritePlayers = [];
    favoritePlayersCache = new Map();
    allPlayers = [];
    marketWinners = [];
    marketLoosers = [];
    userInfo = null;
    marketAll = [];
    calculatedPlayerDataCache = new Map();
    recentSales = [];
    estimatedSalePrices = new Map();
    dbPromise;
    statsCache = new Map();
    leagueInfo = null;
    marketPlayersFetched = false;
    isInitialized = false;

    constructor() {
        makeAutoObservable(this, {
            userId: false // This tells MobX not to make userId observable
        });        const storedLeagueId = localStorage.getItem('selectedLeagueId');
        this.initialize(storedLeagueId);
        this.userId = this.getUserIdFromStorage();
        this.dbPromise = this.openDatabase();
    }

    async openDatabase() {
        return openDB('PlayerDatabase', 2, {
            upgrade(db, oldVersion, newVersion) {
                if (!db.objectStoreNames.contains('players')) {
                    db.createObjectStore('players', { keyPath: 'id' });
                }
            },
        });
    }

    setUserInfo(info) {
        console.log('Setting user info in PlayerStore:', info);
        this.userInfo = info;
    }

    setCurrentLeagueId(id) {
        console.log('Setting current league ID in PlayerStore:', id);
        this.currentLeagueId = id;
    }

    getUserId() {
        return this.userInfo?.id;
    }
    setUserId(id) {
        this.userId = id;
    }

    async savePlayersToDB(players) {
        const db = await this.dbPromise;
        const tx = db.transaction('players', 'readwrite');
        const store = tx.objectStore('players');

        for (const player of players) {
            if (player) {
                await store.put(player);
            }
        }

        await tx.done;
    }

    getPositionCounts() {
        const positionMap = {
            1: 'Torwart',
            2: 'Verteidiger',
            3: 'Mittelfeldspieler',
            4: 'Stürmer',
            'GK': 'Torwart',
            'DEF': 'Verteidiger',
            'MID': 'Mittelfeldspieler',
            'FWD': 'Stürmer'
        };

        return this.players.reduce((counts, player) => {
            const mappedPosition = positionMap[player.position] || player.position;
            counts[mappedPosition] = (counts[mappedPosition] || 0) + 1;
            return counts;
        }, {
            'Torwart': 0,
            'Verteidiger': 0,
            'Mittelfeldspieler': 0,
            'Stürmer': 0
        });
    }

    get investmentLeft() {
        const teamValue = this.userInfo?.teamValue || 0;
        const budget = this.userInfo?.budget || 0;
        const realValue = teamValue + budget;
        let investment;
        if (budget < 0) {
            investment = (realValue * 0.33) + budget;
        } else {
            investment = (teamValue * 0.33) + budget;
        }
        return investment;
    }

    async initData(leagueId = this.leagueId) {
        if (!leagueId) {
            console.error('Invalid league id:', leagueId);
            return;
        }

        if (this.isInitialized && this.leagueId === leagueId) {
            console.log('Data already initialized for this league. Skipping...');
            return;
        }

        console.log(`Initializing data for league: ${leagueId}`);

        try {
            await Promise.all([
                this.fetchUserInfo(leagueId),
                this.fetchPlayers(leagueId),
                this.fetchFavoritePlayers(leagueId),
                this.fetchMarketPlayers(leagueId),
                this.fetchRecentSales()
            ]);

            runInAction(() => {
                this.isInitialized = true;
                this.leagueId = leagueId;
            });

            console.log('Data initialization complete');
        } catch (error) {
            console.error('Error initializing data:', error);
            runInAction(() => {
                this.isInitialized = false;
            });
        }
    }
    async initialize(userId) {
        this.userId = userId;
        const storedLeagueId = localStorage.getItem('selectedLeagueId');
        this._leagueId = storedLeagueId;

        if (this._leagueId && this.userId) {
            try {
                await this.fetchUserInfo(this._leagueId);
                await this.initData(this._leagueId);
            } catch (error) {
                console.error('Error initializing PlayerStore:', error);
            }
        } else {
            console.warn('Missing leagueId or userId for initialization');
        }
    }


    setLeagueId(leagueId) {
        if (!leagueId || leagueId === this._leagueId) {
            return;
        }
        this._leagueId = leagueId;
        localStorage.setItem('selectedLeagueId', leagueId);
        this.initData(leagueId);
    }

    // ----------------- User Related Methods -----------------
    getUserIdFromStorage() {
        const userString = localStorage.getItem("user");
        return JSON.parse(userString)?.id;
    }

    async fetchUserInfo(leagueId = this.leagueId) {
        try {
            this.isTokenExpired();
            const token = localStorage.getItem('token');
            const data = await getUserInfo(token, this.leagueId);
            runInAction(() => {
                this.userInfo = { ...data };
            });
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    }

    renewData() {
        localStorage.removeItem('userPlayers');
        localStorage.removeItem('marketPlayers');
        this.initData(this.leagueId)

    }

    isTokenExpired() {
        const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
        const currentTime = new Date().getTime();
        const tokenTimestamp = localStorage.getItem('token_timestamp');
        const token = localStorage.getItem('token');

        if (!token) return false;

        if (!tokenTimestamp) {
            localStorage.removeItem('token');
            localStorage.removeItem('token_timestamp');
            return true;
        }

        const tokenAge = currentTime - tokenTimestamp;
        if (tokenAge > twoDaysInMilliseconds) {
            localStorage.removeItem('token');
            localStorage.removeItem('token_timestamp');
            return true;
        }
        return false;
    };

    // ----------------- Player Related Methods -----------------
    async fetchPlayers(leagueId = this.leagueId) {
        console.log(`Fetching players for league: ${leagueId}`);
        const userId = this.getUserIdFromStorage();
        if (!userId) {
            console.error('Invalid user data:', userId);
            return;
        }

        try {
            const response = await getPlayerInfo(leagueId, userId);
            console.log('Received player info:', response);

            if (!response?.players) {
                console.error('Invalid response from getPlayerInfo:', response);
                return;
            }

            const playerStatsPromises = response.players.map(async player => {
                const stats = await this.getPlayerStats(leagueId, player.id);
                return { ...player, ...stats };
            });

            const detailedPlayers = await Promise.all(playerStatsPromises);
            console.log(`Fetched ${detailedPlayers.length} players with details`);

            runInAction(() => {
                this.setPlayers(detailedPlayers);
                console.log(`Updated store with ${this.players.length} players`);
            });
        } catch (error) {
            console.error("Error fetching players:", error);
        }
    }

    async getPlayerStatsFromIndexedDB(playerId) {
        return dbManager.get('playerStats', playerId);
    }

    async savePlayerStatsToIndexedDB(playerId, stats) {
        await dbManager.put('playerStats', { id: playerId, ...stats });
    }

    setPlayers(players) {
        console.log(`Setting ${players.length} players`);
        this.players = players.map(playerData => {
            try {
                return new Player(playerData, this.currentMatchDay, false, this);
            } catch (error) {
                console.error('Error creating Player instance:', error, playerData);
                return null;
            }
        }).filter(Boolean); // This removes any null values
        console.log(`Finished setting ${this.players.length} players`);
    }

    //----------------------Favorite Player Related Methods-----------------
    async fetchFavoritePlayers(leagueId = this.leagueId) {
        const cacheKey = `favorites_${leagueId}`;
        const cachedData = this.favoritePlayersCache.get(cacheKey);

        if (cachedData && Date.now() - cachedData.timestamp < 5 * 60 * 1000) { // 5 minutes cache
            runInAction(() => {
                this.favoritePlayers = cachedData.players;
            });
            return;
        }

        try {
            const data = await getFavoritePlayers(leagueId);
            console.log('Received favorite players data:', data);

            runInAction(() => {
                if (data && data.p && Array.isArray(data.p)) {
                    this.favoritePlayers = data.p.map(playerData => new Player(playerData, this.currentMatchDay));
                    this.favoritePlayersCache.set(cacheKey, {
                        players: this.favoritePlayers,
                        timestamp: Date.now()
                    });
                } else {
                    console.error('Unexpected data structure for favorite players:', data);
                    this.favoritePlayers = [];
                }
            });
        } catch (error) {
            console.error("Error fetching favorite players:", error);
            runInAction(() => {
                this.favoritePlayers = [];
            });
        }
    }

    async addPlayerToFavorites(playerId) {
        try {
            const token = localStorage.getItem('token');

        } catch (error) {
            console.error('Failed to add player to favorites:', error);
        }
    }


    async removePlayerFromFavorites(playerId) {
        try {
            const token = localStorage.getItem('token');
            const response = await removeFavoritePlayer(playerId, token);
        } catch (error) {
            console.error('Failed to remove player from favorites:', error);
        }
    }



    // ----------------- Market Player Related Methods -----------------
    async fetchMarketPlayers(leagueId = this.leagueId, force = false) {
        if (this.marketPlayersFetched && !force) {
            console.log('Market players already fetched. Skipping...');
            return;
        }

        console.log('Fetching market players for league:', leagueId);
        try {
            const response = await getMarketPlayers(leagueId);
            if (!response?.players) {
                console.error('Invalid response from getMarketPlayers:', response);
                return;
            }
    
            const marketPlayerStatsPromises = response.players.map(async player => {
                const apiStats = await getPlayerStats(leagueId, player.id).catch(err => {
                    console.error(`Error fetching stats for market player ${player.id}:`, err);
                    return null;
                });
                return { ...player, ...apiStats, isMarketPlayer: true };
            });
    
            const detailedMarketPlayers = await Promise.all(marketPlayerStatsPromises);
            const filteredMarketPlayers = detailedMarketPlayers.filter(Boolean);
    
            runInAction(() => {
                this.setMarketPlayers(filteredMarketPlayers);
                this.marketPlayersFetched = true;
                console.log('Updated market players:', this.marketPlayers);
            });
        } catch (error) {
            console.error("Error fetching market players:", error);
        }
    }
    
    setMarketPlayers(players) {
        console.log('Setting market players:', players.length);
        this.marketPlayers = players
            .filter(player => {
                const isUserPlayer = this.players.some(p => p.id === player.id);
                if (isUserPlayer) {
                    console.log(`Warning: Market player ${player.name} (ID: ${player.id}) is also a user player`);
                }
                return !isUserPlayer;
            })
            .map(playerData => {
                try {
                    return new Player(playerData, this.currentMatchDay, true, this);
                } catch (error) {
                    console.error('Error creating market Player instance:', error, playerData);
                    return null;
                }
            })
            .filter(Boolean);
        console.log('Final market players:', this.marketPlayers.length);
    }
    shouldFetchStatsForMarketPlayer(playerData) {
        return Math.random() < 0.1;
    }
    calculateFitPercentage(player) {
        if (player && typeof player.calculateFitPercentage === 'function') {
            return player.calculateFitPercentage(this);
        } else {
            console.warn(`Player ${player?.name || 'Unknown'} does not have calculateFitPercentage method`);
            return 0; // or some default value
        }
    }


    compareWithTeamPlayers(player) {
        const teamPositionPlayers = this.players.filter(p => p.position === player.position);
        if (teamPositionPlayers.length === 0) return { isBest: true, difference: player.averagePoints };
        
        const bestTeamPlayer = teamPositionPlayers.reduce((best, current) => 
            current.averagePoints > best.averagePoints ? current : best
        );
        
        return {
            isBest: player.averagePoints >= bestTeamPlayer.averagePoints,
            difference: player.averagePoints - bestTeamPlayer.averagePoints
        };
    }

    getPositionRanking(player) {
        const allPositionPlayers = [
            ...this.players,
            ...this.marketPlayers,
            ...this.allPlayers
        ].filter(p => p.position === player.position);

        const uniquePlayers = Array.from(new Set(allPositionPlayers.map(p => p.id)))
            .map(id => allPositionPlayers.find(p => p.id === id));

        const sortedPlayers = uniquePlayers.sort((a, b) => b.averagePoints - a.averagePoints);

        const rank = sortedPlayers.findIndex(p => p.id === player.id) + 1;
        return {
            rank: rank,
            total: sortedPlayers.length
        };
    }

    compareWithCurrentPlayers(player) {
        const allPositionPlayers = [...this.players, ...this.marketPlayers]
            .filter(p => p.position === player.position && p.totalPoints > 0);
        
        if (allPositionPlayers.length === 0) return { averagePointsDiff: 0, percentile: 100 };
        
        const averagePoints = allPositionPlayers.reduce((sum, p) => sum + p.averagePoints, 0) / allPositionPlayers.length;
        const sortedPoints = allPositionPlayers.map(p => p.averagePoints).sort((a, b) => a - b);
        const playerIndex = sortedPoints.findIndex(points => points >= player.averagePoints);
        const percentile = ((allPositionPlayers.length - playerIndex) / allPositionPlayers.length) * 100;
        
        return {
            averagePointsDiff: player.averagePoints - averagePoints || 0,
            percentile: Math.round(percentile) || 0
        };
    }

    // ----------------- League Related Methods -----------------
    getLeagues() {
        return this.leagues;
    }

    set leagueId(id) {
        this._leagueId = id;
        this.initData(id);
    }

    get leagueId() {
        return this._leagueId;
    }
    translatePosition(positionNumber) {
        switch (Number(positionNumber)) {
            case 1: return "Torwart";
            case 2: return "Verteidiger";
            case 3: return "Mittelfeldspieler";
            case 4: return "Stürmer";
            default:
                console.error("Unknown position number:", positionNumber);
                return "Unbekannt";
        }
    }

    // ----------------- Expiry Related Methods -----------------
    formatExpiry(seconds) {
        let days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        let hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        let mins = Math.floor(seconds / 60);

        return [
            days > 0 ? `${days} days` : null,
            hrs > 0 ? `${hrs} hours` : null,
            mins > 0 ? `${mins} minutes` : null
        ].filter(Boolean).join(' ').trim();
    }

    get isExpiryLessThanOneHour() {
        return this.expire < 3600;
    }

    // ----------------- Initialization Methods -----------------


    async fetchRecentSales() {
        try {
            // If getRecentSales doesn't exist, use getLeagueFeed as a fallback
            const recentSales = this.getRecentSales 
                ? await this.getRecentSales(this.leagueId)
                : (await getLeagueFeed(this.leagueId)).items.filter(item => item.type === 12);

            runInAction(() => {
                this.recentSales = recentSales.map(sale => {
                    const player = new Player(sale, this.currentMatchDay, true, this);
                    return {
                        ...sale,
                        player
                    };
                });
            });
            await this.calculateOverpayments();
        } catch (error) {
            console.warn("Error fetching recent sales:", error);
            // Set an empty array to prevent further errors
            runInAction(() => {
                this.recentSales = [];
            });
        }
    }

    async calculateOverpayments() {
        if (!this.leagueId) {
            console.error('LeagueId is not set in PlayerStore');
            return;
        }

        for (const sale of this.recentSales) {
            if (sale.player && typeof sale.player.fetchHistoricalMarketValues === 'function') {
                try {
                    const historicalValues = await sale.player.fetchHistoricalMarketValues();
                    // Rest of your calculation logic
                } catch (error) {
                    console.error(`Error fetching historical market values for player ${sale.player.id}:`, error);
                }
            } else {
                console.warn(`Player ${sale.player?.name || 'Unknown'} does not have fetchHistoricalMarketValues method`);
            }
        }
    }

    async estimateSalePrice(player) {
        if (!player || typeof player !== 'object' || !player.id) {
         //   console.warn(`Invalid player object for price estimation:`, player);
            return null;
        }

        const playerId = player.id;

        const similarSales = await this.fetchSimilarPlayerSales(player.marketValue);
        
        if (similarSales.length === 0) {
        //    console.warn(`No similar sales found for player ${playerId}`);
            return player.marketValue * 1.05;
        }

        const avgSalePercentage = similarSales.reduce((sum, sale) => sum + (sale.salePrice / sale.marketValue), 0) / similarSales.length;

        let adjustedPercentage = avgSalePercentage;
        if (player.form === 'Gute Form') adjustedPercentage *= 1.05;
        if (player.form === 'Sehr gute Form') adjustedPercentage *= 1.1;
        
        const recentTrend = await this.getRecentMarketTrend(playerId);
        adjustedPercentage *= (1 + recentTrend);

        let estimatedPrice = player.marketValue * adjustedPercentage;

        estimatedPrice = Math.max(estimatedPrice, player.marketValue * 1.02);

        console.log(`Estimated sale price for player ${playerId}: ${estimatedPrice.toFixed(2)}`);

        return Math.round(estimatedPrice);
    }

    async fetchSimilarPlayerSales(marketValue) {
        const sales = this.recentSales
            .filter(sale => Math.abs(sale.meta.mv - marketValue) / marketValue < 0.2)
            .map(sale => ({ salePrice: sale.meta.p, marketValue: sale.meta.mv }));

        if (sales.length === 0) {
           // console.warn(`No similar sales found for market value ${marketValue}`);
        }

        return sales;
    }

    async getRecentMarketTrend(playerId) {
        const marketValueHistory = await this.fetchPlayerMarketValueHistory(playerId);
        if (!marketValueHistory || marketValueHistory.length < 2) return 0;

        const recentHistory = marketValueHistory.slice(0, 7);
        const oldestValue = recentHistory[recentHistory.length - 1].m;
        const newestValue = recentHistory[0].m;
        return (newestValue - oldestValue) / oldestValue;
    }

    async fetchPlayerMarketValueHistory(playerId) {
        try {
            const history = await getPlayerValueAtDate(this.leagueId, playerId);
            return history.sort((a, b) => new Date(b.d) - new Date(a.d));
        } catch (error) {
            console.error(`Error fetching market value history for player ${playerId}:`, error);
            return [];
        }
    }

    async fetchAllPlayers(leagueId) {
        const knownPlayerIds = getKnownPlayerIds();
        let fetchedPlayers = [];

        const batchSize = 20;

        for (let i = 0; i < knownPlayerIds.length; i += batchSize) {
            const batchIds = knownPlayerIds.slice(i, i + batchSize);

            const detailedPlayersPromises = batchIds.map(id =>
                Promise.all([
                    this.getPlayerStats(id),
                    getPlayerInfo(leagueId, this.userId)
                ]).then(([playerStats, playerDetailedInfo]) => {
                    return { id, ...playerDetailedInfo, ...playerStats };
                }).catch(err => {
                    console.error(`Error fetching data for Player ID ${id}:`, err);
                    return null;
                })
            );

            try {
                const detailedPlayers = await Promise.all(detailedPlayersPromises);
                fetchedPlayers = fetchedPlayers.concat(detailedPlayers);
                await this.savePlayersToDB(fetchedPlayers.filter(Boolean));
                this.setPlayers(fetchedPlayers.filter(Boolean));
            } catch (error) {
                console.error('Failed to fetch detailed information for player batch:', error);
            }
        }

        return fetchedPlayers;
    }

    calculateIncrease(player, days) {
        if (player.historicalData && player.historicalData.length > days) {
            const latestValue = player.historicalData[0].m;
            const previousValue = player.historicalData[days].m;
            return latestValue - previousValue;
        }
        return null;
    }

    getTopPlayers = (players) => {
        return players
            .map(player => {
                const increase = this.calculateIncrease(player, 1);
                return { ...player, marketValueIncrease: increase };
            })
            .sort((a, b) => b.marketValueIncrease - a.marketValueIncrease)
            .slice(0, 25);
    };


    async getPlayers(leagueId) {
        const savedAt = await localStorage.getItem('savedAt');
        if (savedAt) {
            const savedDate = new Date(savedAt);
            const currentDate = new Date();
            const expiryDate = new Date(savedDate.getTime() + 10 * 60 * 60 * 1000);

            if (currentDate < expiryDate) {
                return this.getPlayersFromDB();
            }
        }

        return this.fetchAllPlayers(leagueId);
    }
    updatePlayersWithMarketData() {
        const ligaInsiderMapped = this.mapLigaInsiderData([...this.marketWinners, ...this.marketLoosers, ...this.marketAll]);

        this.players.forEach(player => {
            const playerKey = player.name
            const matchingLiPlayer = ligaInsiderMapped.find(li => li.key === playerKey);

            if (matchingLiPlayer) {
                player.marketValueLigaInsider = matchingLiPlayer.marketValue;
            } else {
                console.log(`No matching player found for key ${playerKey}`);
            }

            player.marketWinnerRank = this.findRank(this.marketWinners, playerKey);
            player.marketLooserRank = this.findRank(this.marketLoosers, playerKey);
            player.marketAllRank = this.findRank(this.marketAll, playerKey);

        });
    }

    mapLigaInsiderData(data) {
        return data.map(liPlayer => {
            const key = liPlayer.name.replace(/\s+/g, '').toLowerCase();
            return { ...liPlayer, key };
        });
    }

    findRank(array, key) {
        const rank = array.findIndex(li => li.key === key) + 1;
        return rank === 0 ? null : rank;
    }
    getPlayerTraits(playerId, fromMarket = false) {
        if (!playerId) return [];
        const arrayToSearch = fromMarket ? this.marketPlayers : this.players;
        this.fetchLeagueStats(this.leagueId);

        const player = arrayToSearch.find(p => p.id === playerId);
        return player ? player.getTraits() : [];
    }

    async fetchLeagueStats(leagueId) {
        try {
            const leagueStats = await getLeagueStats(leagueId);
            runInAction(() => {
                this.currentMatchDay = leagueStats.currentDay;

            });
        } catch (error) {
            console.error('Error fetching league stats:', error);
        }
    }

    getTeamPlayersCount(teamId) {
        return this.players.filter(player => player.teamId === teamId).length;
    }

    setCachedPlayerData(playerId, data) {
        this.calculatedPlayerDataCache.set(playerId, data);
    }

    getCachedPlayerData(playerId) {
        return this.calculatedPlayerDataCache.get(playerId);
    }

    clearCache() {
        this.calculatedPlayerDataCache.clear();
    }

    async fetchPlayerById(playerId) {
        // Implement this method to fetch player data from your API
        // Return a Player object or null if not found
    }

    async getPlayerStats(leagueId, playerId) {
        if (!playerId) {
            console.warn('Attempted to fetch stats for undefined player');
            return null;
        }
        try {
            const stats = await getPlayerStats(leagueId, playerId.toString());
            return stats;
        } catch (error) {
            console.error(`Error fetching stats for player ${playerId}:`, error);
            return null;
        }
    }

    async getPlayerPoints(playerId) {
        try {
            const points = await getPlayerPoints(playerId);
            return points;
        } catch (error) {
            console.error(`Error fetching points for player ${playerId}:`, error);
            return null;
        }
    }

    getTeamDiversity(player) {
        return this.players.filter(p => p.teamId === player.teamId).length;
    }

    getPositionOccupancy(position) {
        return this.players.filter(p => p.position === position).length;
    }

    getPerformanceRank(player) {
        const sortedPlayers = [...this.players].sort((a, b) => b.averagePoints - a.averagePoints);
        const rank = sortedPlayers.findIndex(p => p.id === player.id) + 1;
        return `${rank}/${sortedPlayers.length}`;
    }

    setLeagueInfo(leagueData) {
        this.leagueInfo = {
            id: leagueData.id,
            name: leagueData.name,
            pl: leagueData.pl,
            mpst: leagueData.mpst,
            // Add other relevant fields
        };
    }

    async fetchLeagueInfo() {
        try {
            const response = await getLeagueUserInfo();
            if (response.leagues && response.leagues.length > 0) {
                runInAction(() => {
                    this.leagueInfo = {
                        id: response.leagues[0].id,
                        name: response.leagues[0].name,
                        pl: response.leagues[0].pl,
                        mpst: response.leagues[0].mpst,
                        // Add other relevant fields
                    };
                });
            }
        } catch (error) {
            console.error('Error fetching league info:', error);
        }
    }

    get userPlayers() {
        console.log('Getting userPlayers');
        console.log('Total players:', this.players.length);
        console.log('Market players:', this.marketPlayers.length);
        const filteredPlayers = this.players.filter(player => {
            const isMarketPlayer = this.marketPlayers.some(mp => mp.id === player.id);
            console.log(`Player ${player.name} (ID: ${player.id}) is ${isMarketPlayer ? 'in' : 'not in'} market`);
            return !isMarketPlayer;
        });
        console.log('Filtered user players:', filteredPlayers.length);
        return filteredPlayers;
    }
}

export const playerStoreInstance = new PlayerStore();

export const PlayerStoreContext = React.createContext(playerStoreInstance);
export const usePlayerStore = () => React.useContext(PlayerStoreContext);
export default PlayerStore;

