import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PlayerComparisonCard from './PlayerComparisonCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faStar, faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';
import { usePlayerStore } from './stores/PlayerStore';
import ClipLoader from "react-spinners/ClipLoader";

Modal.setAppElement('#root');

const PlayerComparisonOverlay = ({ isOpen, onClose, currentPlayer }) => {
    const [alternativePlayers, setAlternativePlayers] = useState([]);
    const [showUnownedOnly, setShowUnownedOnly] = useState(true);
    const playerStore = usePlayerStore();
    const { leagueId } = playerStore;
    const [loading, setLoading] = useState(false);
    const [sortCriteria, setSortCriteria] = useState('marketValue');
    const [sortDirection, setSortDirection] = useState('desc');

    const translatePosition = (position) => {
        if (typeof position === 'number') {
            return playerStore.translatePosition(position);
        }
        // If it's already a string, normalize it
        switch (position) {
            case "Mittelfeld":
            case "Mittelfeldspieler":
                return "Mittelfeldspieler";
            case "Abwehr":
            case "Verteidiger":
                return "Verteidiger";
            case "Angriff":
            case "Stürmer":
                return "Stürmer";
            case "Torwart":
                return "Torwart";
            default:
                console.error("Unknown position:", position);
                return "Unbekannt";
        }
    };

    const toggleFavorite = async (playerId) => {
        const updatedPlayers = alternativePlayers.map(player => {
            if (player.id === playerId) {
                return { ...player, isFavorite: !player.isFavorite };
            }
            return player;
        });
        setAlternativePlayers(updatedPlayers);

        if (playerStore.favoritePlayers.some(p => p.id === playerId)) {
            await playerStore.removePlayerFromFavorites(playerId);
        } else {
            const playerToAdd = alternativePlayers.find(p => p.id === playerId);
            if (playerToAdd) {
                await playerStore.addPlayerToFavorites(playerToAdd);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isOpen && currentPlayer) {
                setLoading(true);

                const players = await playerStore.getPlayers(leagueId);

                if (!Array.isArray(players) || players.length === 0) {
                    console.error("No players received or players is not an array");
                    setLoading(false);
                    return;
                }

                const currentPlayerPosition = translatePosition(currentPlayer.position);

                const filteredAndSortedPlayers = players
                    .filter(player => {
                        const playerPosition = translatePosition(player.position);
                        const positionMatch = playerPosition === currentPlayerPosition;
                        const notCurrentPlayer = player.id !== currentPlayer.id;
                        const isUnowned = !player.leaguePlayer;

                        return positionMatch && notCurrentPlayer && (!showUnownedOnly || isUnowned);
                    })
                    .map(player => ({
                        ...player,
                        name: player.name || `${player.firstName} ${player.lastName}` || 'Unknown Player',
                        positionText: translatePosition(player.position),
                        isFavorite: playerStore.favoritePlayers.some(favPlayer => favPlayer.id === player.id),
                        totalPoints: player.points || 0,
                        averagePoints: player.averagePoints || 0,
                    }));

                // Add current player to the list for comparison
                filteredAndSortedPlayers.unshift({
                    ...currentPlayer,
                    isCurrent: true,
                    positionText: currentPlayerPosition,
                });

                const sortedPlayers = sortPlayers(filteredAndSortedPlayers, sortCriteria, sortDirection);
                setAlternativePlayers(sortedPlayers);
                setLoading(false);
            }
        };

        fetchData();
    }, [isOpen, currentPlayer, leagueId, showUnownedOnly, sortCriteria, sortDirection, playerStore]);

    const sortPlayers = (players, criteria, direction) => {
        return players.sort((a, b) => {
            let comparison = 0;
            switch (criteria) {
                case 'marketValue':
                    comparison = b.marketValue - a.marketValue;
                    break;
                case 'averagePoints':
                    comparison = (b.averagePoints || 0) - (a.averagePoints || 0);
                    break;
                case 'totalPoints':
                    comparison = (b.totalPoints || 0) - (a.totalPoints || 0);
                    break;
                default:
                    comparison = b.marketValue - a.marketValue;
            }
            return direction === 'asc' ? -comparison : comparison;
        });
    };

    const toggleSortDirection = () => {
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };

    const getRankings = (players) => {
        const rankings = {
            marketValue: [...players].sort((a, b) => b.marketValue - a.marketValue),
            averagePoints: [...players].sort((a, b) => (b.averagePoints || 0) - (a.averagePoints || 0)),
            totalPoints: [...players].sort((a, b) => (b.totalPoints || 0) - (a.totalPoints || 0)),
        };

        return players.map(player => ({
            ...player,
            marketValueRank: rankings.marketValue.findIndex(p => p.id === player.id) + 1,
            averagePointsRank: rankings.averagePoints.findIndex(p => p.id === player.id) + 1,
            totalPointsRank: rankings.totalPoints.findIndex(p => p.id === player.id) + 1,
        }));
    };

    const rankedPlayers = getRankings(alternativePlayers);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="ReactModal__Content"
            overlayClassName="ReactModal__Overlay"
        >
            <div className="overlay-content bg-gray-900 text-white rounded-lg shadow-xl w-full h-full max-w-full max-h-full overflow-y-auto overflow-x-hidden">
                <div className="sticky top-0 bg-gray-900 z-10 p-4 border-b border-gray-700">
                    <FontAwesomeIcon icon={faTimes} className="close-icon absolute top-4 right-4 text-xl cursor-pointer" onClick={onClose} />
                    <h2 className="text-xl font-bold mb-4">Vergleichbare Spieler für {currentPlayer?.name}</h2>
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
                        <div className="flex items-center">
                            <span className="mr-2 text-sm">Zeige nur unverkaufte Spieler</span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={showUnownedOnly}
                                    onChange={e => setShowUnownedOnly(e.target.checked)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="flex items-center">
                            <select
                                value={sortCriteria}
                                onChange={e => setSortCriteria(e.target.value)}
                                className="bg-gray-700 text-white p-2 rounded mr-2 text-sm"
                            >
                                <option value="marketValue">Sortieren nach: Marktwert</option>
                                <option value="averagePoints">Sortieren nach: Durchschnittspunkte</option>
                                <option value="totalPoints">Sortieren nach: Gesamtpunkte</option>
                            </select>
                            <FontAwesomeIcon
                                icon={sortDirection === 'desc' ? faSortAmountDown : faSortAmountUp}
                                className="cursor-pointer"
                                onClick={toggleSortDirection}
                            />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <ClipLoader size={50} color={"#123abc"} loading={loading} />
                    </div>
                ) : (
                    <div className="player-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
                        {rankedPlayers.length > 0 ? (
                            rankedPlayers.map(player => (
                                <PlayerComparisonCard
                                    key={player.id}
                                    player={player}
                                    currentPlayer={currentPlayer}
                                    isFavorite={player.isFavorite}
                                    toggleFavorite={() => toggleFavorite(player.id)}
                                />
                            ))
                        ) : (
                            <div className="text-center py-4 col-span-full">
                                Keine vergleichbaren Spieler gefunden.
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PlayerComparisonOverlay;