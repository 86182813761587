import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { usePlayerStore } from './stores/PlayerStore';
import PlayerDetailModal from "./PlayerDetailModal";
import PlayerTimeline from "./PlayerTimeline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,faArrowUp, faCircle, faArrowDown, faTimes, faBinoculars, faArrowRightArrowLeft, faMagicWandSparkles, 
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { playerStoreInstance } from '../src/stores/PlayerStore';
import PlayerSearch from './PlayerSearch';
import ClipLoader from "react-spinners/ClipLoader";
import PlayerComparisonOverlay from './PlayerComparisonOverlay';
import { motion, useAnimation } from 'framer-motion';

const PlayerCard = React.memo(({ player, selectedPlayers, setSelectedPlayers, ...props }) => {
    return (
        <div key={`${player.id}_${props.index}`} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform relative">
            <h4 className="text-2xl font-bold text-white mb-1 absolute top-6 left-6">{player.name}</h4>

            <FontAwesomeIcon
                icon={faSearch}
                className="text-gray-700 hover:text-orange-500 cursor-pointer absolute top-5 right-6"
                onClick={() => {
                    props.setSelectedPlayer(player);
                    props.setTimelineModalOpen(true);
                }}
            />
            <FontAwesomeIcon
                icon={faArrowRightArrowLeft}
                className="text-gray-700 hover:text-orange-500 cursor-pointer absolute top-14 right-5"
                onClick={() => props.setCompareOverlayOpen(true)}
            />
            <label htmlFor={`customCheckbox${player.id}`} className="flex items-center cursor-pointer absolute top-5 right-16">
                <div className="relative">
                    <input
                        id={`customCheckbox${player.id}`}
                        type="checkbox"
                        className="hidden"
                        checked={selectedPlayers.has(player.id)}
                        onChange={(e) => {
                            setSelectedPlayers(prev => {
                                const newSet = new Set(prev);
                                if (e.target.checked) {
                                    newSet.add(player.id);
                                } else {
                                    newSet.delete(player.id);
                                }
                                return newSet;
                            });
                        }}
                    />
                    <div className={`toggle__line w-8 h-3 ${selectedPlayers.has(player.id) ? 'bg-indigo-400' : 'bg-gray-400'} rounded-full shadow-inner`}></div>
                    <div className={`toggle__dot absolute w-5 h-5 ${selectedPlayers.has(player.id) ? 'bg-indigo-600 translate-x-full' : 'bg-white'} rounded-full shadow inset-y-0 left-0 transition-transform duration-300 ease-in-out`}></div>
                </div>
            </label>

            <div className="flex items-start space-x-4 mt-16">
                <div className={`w-32 h-32 mt-2 rounded-full overflow-hidden ${player.status === 2 ? 'border-2 border-yellow-500' : player.status === 1 ? 'border-2 border-red-500' : player.status === 0 ? 'border-2 border-indigo-500' : 'border-2 border-yellow-500'}`}>
                    <img
                        src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                        alt={player.name}
                        className="w-full h-full object-cover transition-opacity duration-700 ease-in-out rounded-full"
                        onLoad={(e) => {
                            e.target.style.opacity = 1;
                        }}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                        }}
                    />
                </div>

                <div className="flex flex-col flex-grow">
                    <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 max-w-max">
                        <span>{player.position}</span>
                    </span>

                    <div className="flex space-x-2 mt-2 mb-2">
                        <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700">
                            <strong>Punkteschnitt:</strong> {player.averagePoints}
                        </span>
                        <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700">
                            <strong>Gesamt:</strong> {player.totalPoints}
                        </span>
                    </div>
                    <button
                        onClick={() => { props.setSelectedPlayer(player); props.setDataModalOpen(true); }}
                        className="text-sm font-bold text-white py-1 px-4 rounded-lg shadow-md bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300"
                    >
                        {player.marketValue.toLocaleString()} €
                    </button>
                    <div className="flex space-x-2 mt-2">
                        <span className="p-2 rounded-md flex items-center shadow-md bg-gray-700 w-32 text-center">
                            <span className="text-sm text-white">
                                <strong> {player.marketValueIncrease.toLocaleString()} €</strong>
                            </span>

                            {player.marketValueIncrease === 0 ? (
                                <FontAwesomeIcon icon={faCircle} className="text-gray-400 ml-2" />
                            ) : player.marketValueIncrease > 0 ? (
                                <FontAwesomeIcon icon={faArrowUp} className="text-green-600 ml-2" />
                            ) : (
                                <FontAwesomeIcon icon={faArrowDown} className="text-red-600 ml-2" />
                            )
                            }

                        </span>
                    </div>
                    <div className="flex space-x-2 mt-2 block">

                        {
                            props.marketValueChange.toLocaleString() !== player.marketValue.toLocaleString() && (
                                <span className="p-2 rounded-md b text-xs flex items-center shadow-md bg-gray-700 w-32 text-center">

                                    {props.marketValueChange.toLocaleString()} €

                                    <span className="text-xs text-white">

                                        {props.marketValueChange === 0 ? (
                                            <FontAwesomeIcon icon={faCircle} className="text-gray-400 ml-2" />
                                        ) : props.marketValueChange > 0 ? (
                                            <FontAwesomeIcon icon={faArrowUp} className="text-green-600 ml-2" />
                                        ) : (
                                            <FontAwesomeIcon icon={faArrowDown} className="text-red-600 ml-2" />
                                        )
                                        }
                                    </span>
                                </span>
                            )
                        }
                    </div>
                    

                    <div className="flex space-x-2 mt-2 mb-2">
                        <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 flex items-center">
                            <strong>{props.homeOrAway}</strong>
                            {props.opponentTeamId && (
                                <img
                                    src={`https://kickbase.b-cdn.net/pool/teams/${props.opponentTeamId}.png`}
                                    alt={`Opponent Team ${props.opponentTeamId} Logo`}
                                    className="ml-2 w-6 h-6 object-cover rounded-full"
                                    onError={(e) => {
                                        e.currentTarget.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${props.opponentTeamId}.png`;
                                    }}
                                />
                            )}
                        </span>
                    </div>
                </div>
            </div>

            {/* Simplified Empfehlung section */}
            <div className="mt-4">
                
                {props.showFitInfo[player.id] && (
                    <div className="mt-2 bg-gray-700 p-4 rounded-lg space-y-2 text-xs">
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Form:</span>
                            <span>{player.form || 'Normale Form'}</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Tägliche Wertsteigerung:</span>
                            <span>{player.marketValueIncrease.toLocaleString()} €</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Zukünftiger Trend:</span>
                            <span>{player.futureValueIncrease?.toLocaleString() || '-'} €</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Wertentwicklung (7 Tage):</span>
                            <span>→ {props.marketValueTrend}</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Vergleich auf der Pos.:</span>
                            <span>{props.positionRanking}</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Freie Plätze auf Pos.:</span>
                            <span>{props.playerStore.getPositionCounts()[player.position]} / {player.position === 'Torwart' ? 1 : player.position === 'Sturm' ? 3 : 5}</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Spieler aus dem Team:</span>
                            <span>{props.playerStore.players.filter(p => p.teamId === player.teamId).length} im Team</span>
                        </div>
                        <div className="bg-gray-600 p-2 rounded flex items-center justify-between">
                            <span className="font-semibold">Spiele absolviert:</span>
                            <span>{player.gamesPlayed || 0} / {player.totalGames || 0}</span>
                        </div>
                    </div>
                )}
            </div>

            {!player.seasonData && (
                <div className="text-sm mt-2"></div>
            )}
        </div>
    );
});

const TeamOverview = observer(() => {
    const playerStore = usePlayerStore();

    const [dataModalOpen, setDataModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [sortType, setSortType] = useState('default');
    const [filterPosition, setFilterPosition] = useState('All');
    const [timelineModalOpen, setTimelineModalOpen] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState(new Set());
    const [budget, setBudget] = useState(0);
    const [view, setView] = useState('team');
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [playerTraits, setPlayerTraits] = useState({});
    const [showFitInfo, setShowFitInfo] = useState({});
    const [compareOverlayOpen, setCompareOverlayOpen] = useState(false);
    const [currentPlayerForComparison, setCurrentPlayerForComparison] = useState(null);
    const inputRef = useRef(null); // Create a ref for the input
    const [possibleFormations, setPossibleFormations] = useState([]);
    const [estimatedPoints, setEstimatedPoints] = useState(0);

    const sortedFilteredPlayers = useMemo(() => {
        console.log('Recalculating sortedFilteredPlayers');
        console.log('playerStore.userPlayers:', playerStore.userPlayers);
        
        // Ensure playerStore.userPlayers is an array
        const players = Array.isArray(playerStore.userPlayers) ? playerStore.userPlayers : [];
        console.log('Players after array check:', players);
        
        let filteredPlayers = filterPosition !== 'All'
            ? players.filter(player => player.position === filterPosition)
            : players;
        console.log('Filtered players:', filteredPlayers);

        switch (sortType) {
            case 'marketValue':
                return [...filteredPlayers].sort((a, b) => b.marketValue - a.marketValue);
            case 'averagePoints':
                return [...filteredPlayers].sort((a, b) => b.averagePoints - a.averagePoints);
            case 'pointsPerEuro':
                return [...filteredPlayers].sort((a, b) => (b.averagePoints / b.marketValue) - (a.averagePoints / a.marketValue));
            case 'valuePotential':
                return [...filteredPlayers].sort((a, b) => b.marketValueTrend - a.marketValueTrend);
            case 'valueForMoney':
                return [...filteredPlayers].sort((a, b) => (b.totalPoints / b.buyPrice) - (a.totalPoints / a.buyPrice));
            case 'valueChange':
                return [...filteredPlayers].sort((a, b) => b.marketValueIncrease - a.marketValueIncrease);
            case 'position':
                return [...filteredPlayers].sort((a, b) => getPositionSortOrder(a.position) - getPositionSortOrder(b.position));
            default:
                return [...filteredPlayers].sort((a, b) => b.marketValueIncrease - a.marketValueIncrease);
        }

        console.log('Final sorted and filtered players:', filteredPlayers);
        return filteredPlayers;
    }, [playerStore.userPlayers, filterPosition, sortType]);

    useEffect(() => {
        const fetchData = async () => {
            console.log("Fetching data...");
            setError(null);
            setLoading(true);
            try {
                if (!playerStore.leagueId) {
                    throw new Error('League ID is not available');
                }
                await playerStore.fetchPlayers();
                await playerStore.fetchMarketPlayers();
                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(`Failed to fetch data: ${err.message}`);
                setLoading(false);
            }
        };

        fetchData();
    }, [playerStore, playerStore.leagueId]);

    useEffect(() => {
        playerStore.initData();
        const intervalId = setInterval(async () => {
            const budget = playerStore.userInfo?.budget || 0;
            console.log('Current budget:', budget);
            setBudget(budget);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [playerStore]);

    const filterByPosition = (players, position) => players.filter(player => player.position === position);
    const filterByStatus = (players, status) => players.filter(player => player.status === status);
    const filterByValueTrend = (players, trend) => players.filter(player => player.calculateMVTrend() >= trend);
    const filterByValueChange = (players, change) => players.filter(player => player.calculateValueChange() >= change);

    const getPositionSortOrder = (position) => {
        const order = { 'Torwart': 1, 'Abwehr': 2, 'Mittelfeld': 3, 'Sturm': 4 };
        return order[position] || 5;
    };

    const handleSortChange = (e) => {
        console.log('Changing sort type to:', e.target.value);
        setSortType(e.target.value);
    };

    const handleFilterChange = (e) => {
        console.log('Changing filter position to:', e.target.value);
        setFilterPosition(e.target.value);
    };

    const sortPlayersByPosition = (a, b) => {
        return getPositionSortOrder(a.position) - getPositionSortOrder(b.position);
    };


    function getOfferPrice(playerData) {
        const offerPrice = playerData.firstOfferPrice;
        return offerPrice || playerData.marketValue;
    }
    const selectedMarketValue = Array.from(selectedPlayers)
        .map(playerId => {
            const player = sortedFilteredPlayers.find(p => p.id === playerId);
            return player ? (player.firstOfferPrice || player.marketValue) : 0;
        })
        .reduce((a, b) => a + b, 0);

    const budgetBalance = selectedMarketValue + budget;

    const totalMarketValue = useMemo(() => {
        return sortedFilteredPlayers.reduce((sum, player) => sum + (player.marketValueIncrease || 0), 0);
    }, [sortedFilteredPlayers]);

    const useMarketValueDisplay = (totalMarketValue) => {
        const controls = useAnimation();

        useEffect(() => {
            controls.start({ scale: [1, 1.1, 1] }, { duration: 0.3 });
        }, [totalMarketValue, controls]);

        return { displayedMarketValue: totalMarketValue, controls };
    };

    const { displayedMarketValue, controls } = useMarketValueDisplay(totalMarketValue);

    const closeFooterBar = () => {
        setSelectedPlayers(new Set());
    }

    const groupedPlayers = useMemo(() => {
        if (sortType !== 'position') return [];

        const groups = sortedFilteredPlayers.reduce((acc, player) => {
            const group = acc.find(g => g.position === player.position);
            if (group) {
                group.players.push(player);
                group.totalMarketValue += player.marketValue;
                group.totalPoints += player.totalPoints;
                group.averagePoints += player.averagePoints / group.players.length;
            } else {
                acc.push({ position: player.position, players: [player], totalMarketValue: player.marketValue, totalPoints: player.totalPoints, averagePoints: player.averagePoints });
            }
            return acc;
        }, []);

        return groups.sort((a, b) => getPositionSortOrder(a.position) - getPositionSortOrder(b.position));
    }, [sortedFilteredPlayers, sortType]);

    const toggleFitInfo = (playerId) => {
        setShowFitInfo(prev => ({ ...prev, [playerId]: !prev[playerId] }));
    };

    const getFitColor = (percentage) => {
        if (percentage >= 70) return 'text-green-500';
        if (percentage >= 40) return 'text-yellow-500';
        return 'text-red-500';
    };

    const backgroundColor = totalMarketValue > 0 ? 'bg-green-600' : 'bg-red-600';

    const closeAllModals = () => {
        setDataModalOpen(false);
        setTimelineModalOpen(false);
        setSearchModalOpen(false);
        setCompareOverlayOpen(false);
    };

    useEffect(() => {
        if (searchModalOpen && inputRef.current) {
            inputRef.current.focus(); // Focus the input when the modal opens
        }
    }, [searchModalOpen]); // Run effect when modal open state changes

    const calculateMarketValueTrend = (player) => {
        const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        const oldValue = player.getMarketValueAtDate(sevenDaysAgo);
        const currentValue = player.marketValue;
        if (oldValue && currentValue) {
            const percentageChange = ((currentValue - oldValue) / oldValue) * 100;
            if (percentageChange > 5) return "Steigend";
            if (percentageChange < -5) return "Fallend";
            return "Stabil";
        }
        return "Unbekannt";
    };

    const autoSelectPlayersToSell = useCallback(() => {
        console.log('autoSelectPlayersToSell called');
        console.log('Initial selectedPlayers:', selectedPlayers);
        console.log('Initial sortedFilteredPlayers:', sortedFilteredPlayers);
        console.log('Initial budget:', budget);

        // Start with all players selected
        const newSelectedPlayers = new Set(sortedFilteredPlayers.map(p => p.id));
        let currentBalance = budget;

        console.log('Initial selection:', newSelectedPlayers);
        console.log('Initial balance:', currentBalance);

        // Sort players by criteria (worst performers first, so we sell them)
        const sortedPlayers = [...sortedFilteredPlayers].sort((a, b) => {
            if (a.averagePoints !== b.averagePoints) return a.averagePoints - b.averagePoints;
            if (a.totalPoints !== b.totalPoints) return a.totalPoints - b.totalPoints;
            return (a.totalPoints / a.marketValue) - (b.totalPoints / b.marketValue);
        });

        console.log('Sorted players to potentially sell:', sortedPlayers.map(p => p.name));

        // Remove players until we have a non-negative balance or can't remove more
        for (const playerToSell of sortedPlayers) {
            if (currentBalance >= 0) {
                console.log('Positive balance reached');
                break;
            }

            if (newSelectedPlayers.size <= 11) {
                console.log('Minimum team size reached');
                break;
            }

            newSelectedPlayers.delete(playerToSell.id);
            currentBalance += playerToSell.marketValue;
            console.log(`Player sold: ${playerToSell.name}, New balance: ${currentBalance}`);

            // Check if we still have a valid selection
            const remainingPlayers = sortedFilteredPlayers.filter(p => newSelectedPlayers.has(p.id));
            console.log('Remaining players:', remainingPlayers.map(p => p.name));
            console.log('Current position counts:', countPositions(remainingPlayers));
            
            if (!isValidSelection(remainingPlayers)) {
                console.log('Cannot sell more players without invalidating selection');
                newSelectedPlayers.add(playerToSell.id); // Add back the last player we tried to sell
                currentBalance -= playerToSell.marketValue;
                break;
            }
        }

        console.log('Final newSelectedPlayers:', newSelectedPlayers);
        console.log('Final balance:', currentBalance);
        setSelectedPlayers(newSelectedPlayers);
        updatePossibleFormations(sortedFilteredPlayers.filter(p => newSelectedPlayers.has(p.id)));
        calculateEstimatedPoints(sortedFilteredPlayers.filter(p => newSelectedPlayers.has(p.id)));
    }, [sortedFilteredPlayers, selectedPlayers, budget]);

    const isValidSelection = (players) => {
        const counts = countPositions(players);
        const isValid = counts.Torwart >= 1 && 
                        (counts.Abwehr + counts.Verteidiger) >= 3 && 
                        counts.Mittelfeld >= 2 && 
                        counts.Sturm >= 1 && 
                        (counts.Abwehr + counts.Verteidiger + counts.Mittelfeld + counts.Sturm) >= 10;
        console.log('Is selection valid:', isValid, 'Counts:', counts);
        return isValid;
    };

    const updatePossibleFormations = (players) => {
        const counts = countPositions(players);
        const possible = ['3-4-3', '3-5-2', '3-6-1', '4-2-4', '4-3-3', '4-4-2', '4-5-1', '5-2-3', '5-3-2', '5-4-1'].filter(formation => {
            const [def, mid, fwd] = formation.split('-').map(Number);
            return counts.Abwehr >= def && counts.Mittelfeld >= mid && counts.Sturm >= fwd;
        });
        setPossibleFormations(possible);
    };

    const countPositions = (players) => {
        return players.reduce((counts, player) => {
            counts[player.position] = (counts[player.position] || 0) + 1;
            return counts;
        }, { Torwart: 0, Abwehr: 0, Mittelfeld: 0, Sturm: 0 });
    };

    const calculateEstimatedPoints = (playersToKeep) => {
        const totalPoints = playersToKeep.reduce((sum, player) => sum + player.averagePoints, 0);
        console.log('Estimated points:', totalPoints);
        setEstimatedPoints(totalPoints);
    };

    if (error) {
        return (
            <div className="flex flex-col justify-center items-center min-h-screen text-red-500">
                <p>{error}</p>
                <button 
                    onClick={() => {
                        setError(null);
                        setLoading(true);
                        playerStore.getPlayers(playerStore.leagueId)
                            .then(() => setLoading(false))
                            .catch((err) => {
                                console.error('Error retrying fetch:', err);
                                setError(`Failed to fetch players: ${err.message}`);
                                setLoading(false);
                            });
                    }} 
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Retry
                </button>
            </div>
        );
    }

    useEffect(() => {
        console.log(`Total players in store: ${playerStore.players.length}`);
        console.log(`Rendered players: ${sortedFilteredPlayers.length}`);
    }, [playerStore.players, sortedFilteredPlayers]);

    const MemoizedPlayerCard = React.memo(PlayerCard, (prevProps, nextProps) => {
        return prevProps.player.id === nextProps.player.id &&
               prevProps.selectedPlayers.has(prevProps.player.id) === nextProps.selectedPlayers.has(nextProps.player.id);
    });

    const MemoizedMarketValueDisplay = React.memo(({ displayedMarketValue, controls }) => {
        const value = displayedMarketValue;
        return (
            <div className={`bg-indigo-600 text-white p-1 rounded-lg shadow-md mb-4 text-center ${value > 0 ? 'bg-green-600' : 'bg-red-600'}`}>
                <h2 className="text-sm font-semibold">Marktwertveränderung heute</h2>
                <motion.p
                    className="text-md font-bold mt-1"
                    animate={controls}
                >
                    {value.toLocaleString()} €
                    {value > 0 ? (
                        <FontAwesomeIcon icon={faArrowUp} className="text-green-400 ml-1" />
                    ) : value < 0 ? (
                        <FontAwesomeIcon icon={faArrowDown} className="text-red-400 ml-1" />
                    ) : null}
                </motion.p>
            </div>
        );
    });

    const PlayerList = React.memo(({ players, setSelectedPlayer, setTimelineModalOpen, setCompareOverlayOpen, selectedPlayers, setSelectedPlayers, playerStore, setDataModalOpen }) => {
        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 mt-8 items-start justify-center">
                {players.map((player, index) => {
                    // Use a combination of player.id and index as the key
                    const uniqueKey = `${player.id}_${index}`;
                    const marketValueChange = player.marketValue - player.buyPrice;
                    const opponentTeamId = player.nextOpponent ? player.nextOpponent.teamId : null;
                    const homeOrAway = player.nextOpponent ? player.nextOpponent.homeOrAway : 'Unknown';

                   // const playerTraits = player.getTraits();
                    const fitPercentage = player.calculateFitPercentage(playerStore);
                    const comparisonResult = playerStore.compareWithTeamPlayers(player);
                    const positionRanking = playerStore.getPositionRanking(player);
                    const marketValueTrend = calculateMarketValueTrend(player);

                    return (
                        <MemoizedPlayerCard
                            key={uniqueKey}
                            player={player}
                            index={index}
                            setSelectedPlayer={setSelectedPlayer}
                            setTimelineModalOpen={setTimelineModalOpen}
                            setCompareOverlayOpen={setCompareOverlayOpen}
                            selectedPlayers={selectedPlayers}
                            setSelectedPlayers={setSelectedPlayers}
                            marketValueChange={marketValueChange}
                            opponentTeamId={opponentTeamId}
                            homeOrAway={homeOrAway}
                            showFitInfo={showFitInfo}
                            playerStore={playerStore}
                            positionRanking={positionRanking}
                            marketValueTrend={marketValueTrend}
                            setDataModalOpen={setDataModalOpen}
                        />
                    );
                })}
            </div>
        );
    });

    const handleAutoSelectPlayersToSell = useCallback(() => {
        console.log('Magic wand button clicked');
        const result = autoSelectPlayersToSell();
        console.log('After autoSelectPlayersToSell:', result);
        
        setSelectedPlayers(new Set(result.playersToSell.map(p => p.id)));
        setBudget(result.finalBalance);
    }, [autoSelectPlayersToSell]);

    return (
        <div className="relative">
            {/* Filter Section  */}
            <div className="bg-gray-800 text-white p-4 rounded shadow-md flex flex-row justify-between items-center mb-4">
                <div className="flex-1 flex justify-between items-center">
                    <div className="text-left mr-4">
                        <select
                            value={sortType}
                            onChange={handleSortChange}
                            className="w-auto p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        >
                            <option value="default">Standard</option>
                            <option value="marketValue">Marktwert</option>
                            <option value="position">Position</option>
                            <option value="averagePoints">Punkteschnitt</option>
                            <option value="pointsPerEuro">Punkteschnitt/Euro</option>
                            <option value="valueChange">MW-Veränderung</option>
                            <option value="valueForMoney">Gesamtpunkte/Euro</option>
                        </select>
                    </div>
                    
                </div>
            </div>
            <MemoizedMarketValueDisplay displayedMarketValue={displayedMarketValue} controls={controls} />
            <PlayerList
                players={sortedFilteredPlayers}
                setSelectedPlayer={setSelectedPlayer}
                setTimelineModalOpen={setTimelineModalOpen}
                setCompareOverlayOpen={setCompareOverlayOpen}
                selectedPlayers={selectedPlayers}
                setSelectedPlayers={setSelectedPlayers}
                playerStore={playerStore}
                setDataModalOpen={setDataModalOpen}
            />
            {
                compareOverlayOpen && (
                    <PlayerComparisonOverlay
                        isOpen={compareOverlayOpen}
                        onClose={() => setCompareOverlayOpen(false)}
                        currentPlayer={currentPlayerForComparison}
                    />
                )}
            {
                timelineModalOpen && selectedPlayer && (
                    <Modal
                        isOpen={timelineModalOpen}
                        onRequestClose={closeAllModals} // Close modal on overlay click
                        overlayClassName="ReactModal__Overlay"
                        className="ReactModal__Content"
                    >
                        <button
                            className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                            onClick={closeAllModals} // Close modal
                        >
                            &times;
                        </button>
                        <PlayerTimeline playerId={selectedPlayer.id} onClose={closeAllModals} />
                    </Modal>
                )
            }

            {
                dataModalOpen && selectedPlayer && (
                    <Modal
                        isOpen={dataModalOpen}
                        onRequestClose={closeAllModals} // Close modal on overlay click
                        overlayClassName="ReactModal__Overlay"
                        className="ReactModal__Content"
                    >
                        <button
                            className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                            onClick={closeAllModals} // Close modal
                        >
                            &times;
                        </button>
                        <PlayerDetailModal player={selectedPlayer} onClose={closeAllModals} />
                    </Modal>
                )
            }
            {
                searchModalOpen && (
                    <Modal
                        isOpen={searchModalOpen}
                        onRequestClose={closeAllModals} // Close modal on overlay click
                        overlayClassName="fixed inset-0 flex items-center justify-center z-50"
                        className="bg-transparent relative" // Ensure the modal is relative
                    >
                        <button
                            className="absolute top-1 right-1 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                            onClick={closeAllModals} // Close modal
                            style={{ zIndex: 1001 }} // Ensure it's above other content
                        >
                            &times;
                        </button>
                        <PlayerSearch inputRef={inputRef} onClose={closeAllModals} />
                    </Modal>
                )
            }

            {loading && sortedFilteredPlayers.length === 0 && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <motion.div animate={{ scale: [1, 1.2, 1] }} transition={{ duration: 0.5, repeat: Infinity }}>
                        <ClipLoader size={50} color={"#123abc"} loading={loading} />
                    </motion.div>
                </div>
            )}

       
            {selectedPlayers.size > 0 && (
                <div className={`p-1 sm:p-2 md:p-3 fixed bottom-0 left-0 w-full flex flex-col md:flex-row justify-center md:justify-between items-center transition-all ease-in-out bg-opacity-97 safe-area-container ${budgetBalance >= 0 ? 'bg-green-400' : 'bg-red-400'}`}>
                    <div className="w-full flex flex-col md:flex-row md:justify-between space-y-1 md:space-y-0 text-center">
                        <span className="text-xs sm:text-sm md:text-xl text-gray-700 md:text-lg py-0 md:py-1 px-1 sm:px-2 md:px-3 rounded-full md:mt-0 mt-2 ">
                            <strong>{selectedPlayers.size}</strong> Spieler ausgewählt 
                            {sortedFilteredPlayers.length - selectedPlayers.size <= 10 && (
                                <span className="ml-2 text-red-600 font-bold">
                                    Nur noch {sortedFilteredPlayers.length - selectedPlayers.size} Spieler übrig!
                                </span>
                            )}
                        </span>
                        <span className="text-xs sm:text-sm md:text-xl text-gray-700 md:text-lg py-0 md:py-1 px-1 sm:px-2 md:px-3 rounded-full">Marktwert: <strong>{selectedMarketValue.toLocaleString('de-DE')} € </strong></span>
                        <span className="text-xs sm:text-sm md:text-xl text-gray-700 md:text-lg py-0 md:py-1 px-1 sm:px-2 md:px-3 rounded-full safe-area-container">Kontostand nach Verkauf: <strong>{budgetBalance.toLocaleString('de-DE')}  € </strong></span>
                        <span className="text-xs sm:text-sm md:text-xl text-gray-700 md:text-lg py-0 md:py-1 px-1 sm:px-2 md:px-3 rounded-full">
                            Verbleibend: <strong>{sortedFilteredPlayers.length - selectedPlayers.size}</strong> Spieler
                        </span>
                        <span className="text-xs sm:text-sm md:text-xl text-gray-700 md:text-lg py-0 md:py-1 px-1 sm:px-2 md:px-3 rounded-full">
                            Geschätzte Punkte: <strong>{estimatedPoints.toFixed(2)}</strong>
                        </span>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={() => setSelectedPlayers(new Set())}
                            className="text-black text-lg hover:text-gray-400 transition-all"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </div >
    );

});
function getOpponentLogoURL(teamId) {
    return `/teams/${teamId}.png`;
}

export default TeamOverview;

