import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import TeamOverview from './TeamOverview';
import MarketOverview from './MarketOverview';
import ScoutingOverview from './ScoutingOverview';
import TopPlayersOverview from './TopPlayersOverview';
import { observer } from 'mobx-react';
import { getLeagueUserInfo } from './api';
import { playerStoreInstance } from '../src/stores/PlayerStore';
import { collectLeagueBonus, getCurrentLeagueGift } from './api';
import { toast } from 'react-toastify'; // Import toast for notifications

const Dashboard = observer((leagueId) => {
    const views = ['Team', 'Markt', 'Scouting'];
    const [view, setView] = useState(views[0]);
    const adContainerRef = useRef(null);
    const [giftAvailable, setGiftAvailable] = useState(false);
    const [bonusCollected, setBonusCollected] = useState(false); // State to track bonus collection
    const currentIndex = views.indexOf(view);

    const handleSwipeLeft = () => setView(views[(currentIndex + 1) % views.length]);
    const handleSwipeRight = () => setView(views[(currentIndex - 1 + views.length) % views.length]);

    const handlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        delta: 155
    });

    const [selectedLeagueId, setSelectedLeagueId] = useState(null);
    const [leagues, setLeagues] = useState([]);
    let giftInfo;

    const handleLeagueChange = (leagueId) => {
        setSelectedLeagueId(leagueId);
        playerStoreInstance.fetchPlayers(leagueId);
    };

    const handleCollectBonus = async () => {
        if (bonusCollected) return; // Prevent collecting again
        try {
            const bonusResponse = await collectLeagueBonus(selectedLeagueId);
            setBonusCollected(true); // Mark bonus as collected
            toast.success(`Bonus abgerufen: ${bonusResponse.amount} €`); // Show success toast
        } catch (error) {
            toast.error('Fehler beim Abrufen des Bonus'); // Show error toast
        }
    };

    useEffect(() => {
        const checkGiftAvailability = async () => {
            try {
                giftInfo = await getCurrentLeagueGift(selectedLeagueId);
                if (giftInfo && giftInfo.isAvailable) {
                    setGiftAvailable(true);
                    handleCollectBonus(); // Automatically collect bonus on render
                }
            } catch (error) {
                console.error('Error checking gift availability:', error);
            }
        };

        if (selectedLeagueId) {
            checkGiftAvailability();
        }
    }, [selectedLeagueId]);

    useEffect(() => {
        async function fetchLeagues() {
            const token = localStorage.getItem('token');
            if (token) {
                const leagueUserInfo = await getLeagueUserInfo(token);
                if (leagueUserInfo && leagueUserInfo.leagues) {
                    setLeagues(leagueUserInfo.leagues);
                }
            }
        }
        fetchLeagues();
    }, []);

    return (
        <div {...handlers} className="bg-gray-100 min-h-screen" style={{ backgroundColor: "#15141a" }}>
            <div className="container mx-auto px-4 py-8 text-white">
                <div className="relative w-62 mb-5 bg-black bg-opacity-60 rounded overflow-hidden cursor-pointer mx-auto sm:mx-0">
                    {giftAvailable && (
                        <div className="my-4 text-center">
                            <button
                                className="bg-custom-orange text-white font-bold py-2 px-4 rounded"
                                onClick={handleCollectBonus}
                            >
                                {giftInfo.amount + " "} Bonus abholen
                            </button>
                        </div>
                    )}
                    <div className="flex h-full w-full">
                        {views.map((viewName) => (
                            <div
                                key={viewName}
                                className={`flex-1 p-1 flex items-center justify-center text-center overflow-hidden cursor-pointer ${view === viewName ? 'bg-custom-orange text-black' : 'text-white'}`}
                                onClick={() => setView(viewName)}
                            >
                                {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
                            </div>
                        ))}
                    </div>
                </div>

                {view === 'Team' && <TeamOverview />}
                {view === 'Markt' && <MarketOverview />}
                {view === 'Scouting' && <ScoutingOverview />}
                {/* {view === 'Topspieler' && <TopPlayersOverview />} */}
                <div className="bg-black bg-opacity-50 rounded p-4 mt-10 mb-6 mx-auto text-center max-w-lg">
                    <p>Diese Seite ist komplett kostenlos und werbefrei. Vielleicht mögt ihr mich mit einer Spende unterstützen, um die Serverkosten zu bezahlen. Der Button ist oben auf der Seite. Danke.</p>
                </div>
            </div>
        </div>
    );
});

export default Dashboard;