import {openDB} from 'idb';
import LZString from 'lz-string';

const DB_NAME = 'PlayerDatabase';
const DB_VERSION = 2;
const TIMESTAMP_KEY = 'db_timestamp';

class DatabaseManager {
    static instance = null;
    dbPromise = null;

    constructor() {
        if (DatabaseManager.instance) {
            return DatabaseManager.instance;
        }
        DatabaseManager.instance = this;
        this.dbPromise = this.openDatabase();
    }

    async openDatabase() {
        const db = await openDB(DB_NAME, DB_VERSION, {
            upgrade: (db, oldVersion, newVersion, transaction) => {
                console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);
                if (oldVersion < 1) {
                    db.createObjectStore('players', {keyPath: 'id'});
                    db.createObjectStore('playerStats', {keyPath: 'id'});
                    db.createObjectStore('cachedPlayers', {keyPath: 'id'});
                }
                if (oldVersion < 2) {
                    db.createObjectStore('metadata', {keyPath: 'key'});
                }
                // Set the timestamp
                const metadataStore = transaction.objectStore('metadata');
                metadataStore.put({key: TIMESTAMP_KEY, value: Date.now()});
            },
        });

        // Check if the database is older than 24 hours
        const timestamp = await this.getTimestamp(db);
        if (!timestamp || Date.now() - timestamp > 24 * 60 * 60 * 1000) {
            console.log('Database is older than 24 hours or timestamp not found. Recreating...');
            await this.recreateDatabase(db);
        }

        return db;
    }

    async getTimestamp(db) {
        const tx = db.transaction('metadata', 'readonly');
        const store = tx.objectStore('metadata');
        const timestampObj = await store.get(TIMESTAMP_KEY);
        return timestampObj ? timestampObj.value : null;
    }

    async recreateDatabase(db) {
        // Delete all data
        const stores = db.objectStoreNames;
        const tx = db.transaction(stores, 'readwrite');
        for (let storeName of stores) {
            await tx.objectStore(storeName).clear();
        }
        await tx.done;

        // Set new timestamp
        const metadataTx = db.transaction('metadata', 'readwrite');
        const metadataStore = metadataTx.objectStore('metadata');
        await metadataStore.put({key: TIMESTAMP_KEY, value: Date.now()});
        await metadataTx.done;
    }

    async get(storeName, key) {
        const db = await this.dbPromise;
        return db.get(storeName, key);
    }

    async put(storeName, value) {
        const db = await this.dbPromise;
        return db.put(storeName, value);
    }

    async getAll(storeName) {
        const db = await this.dbPromise;
        return db.getAll(storeName);
    }

    async getCachedPlayers() {
        const cachedData = await this.get('cachedPlayers', 'userPlayers');
        if (cachedData) {
            return {
                players: JSON.parse(LZString.decompressFromUTF16(cachedData.players)),
                timestamp: cachedData.timestamp
            };
        }
        return null;
    }

    async saveCachedPlayers(players, timestamp) {
        const compressedPlayers = LZString.compressToUTF16(JSON.stringify(players));
        await this.put('cachedPlayers', {
            id: 'userPlayers',
            players: compressedPlayers,
            timestamp
        });
    }
}

export const dbManager = new DatabaseManager();