import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCSKl6kC3tRFdHJJbOrAohZPVv7bL4bDa8",
    authDomain: "kickbase-analytic.firebaseapp.com",
    projectId: "kickbase-analytic",
    storageBucket: "kickbase-analytic.appspot.com",
    messagingSenderId: "711239456594",
    appId: "1:711239456594:web:44ceca1905dec0c24c8c8b",
    measurementId: "G-RX05XRD78G"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics };