import React, { useState, useEffect } from 'react';
import { playerStoreInstance } from './stores/PlayerStore';
import LeagueSelector from './LeagueSelector';
import { useObserver } from 'mobx-react-lite';
import { ClipLoader } from 'react-spinners';

const Header = ({ user, onLogout }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showLeagueModal, setShowLeagueModal] = useState(false);
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const formatCurrency = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);

    useEffect(() => {
        const checkUserInfo = () => {
            if (playerStoreInstance.userInfo) {
                setLoading(false);
            }
        };

        checkUserInfo();
        const intervalId = setInterval(checkUserInfo, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const teamValue = playerStoreInstance.userInfo?.teamValue || 0;
    const budget = playerStoreInstance.userInfo?.budget || 0;
    const realValue = teamValue + budget;
    let investmentLeft = (realValue * 0.33) + budget;
    if (budget < 0) {
        const realValue = teamValue + budget;
        investmentLeft = (realValue * 0.33) + budget;
    } else {
        investmentLeft = (teamValue * 0.33) + budget;
    }
    const handleLeagueChange = (selectedLeague) => {
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.reload(); // to refresh the page
    };

    return useObserver(() => (
        <div className="bg-gray-800 text-white py-3  lg:py-2 lg:px-4 flex justify-between items-center">
            <div className="flex items-center space-x-4 md:order-none order-1 mt-2 md:mt-0">
                <img src={require('./analyticlogo.png')} className="w-40 h-15 md:block hidden" alt="Analytic Logo" />
                <img src={require('./analyticlogo.png')} className="w-18 h-10 md:hidden block ml-[value]" alt="Analytic Logo" />

                <button className="md:hidden bg-gray-700 p-1.5 rounded" onClick={() => setShowLeagueModal(true)}>Liga</button>
                <button className="md:hidden bg-gray-700 p-1.5 rounded" onClick={() => setShowBudgetModal(true)}>Finanzen</button>
                <button className="md:hidden bg-gray-700 p-1.5 rounded" onClick={() => setShowLogoutModal(true)}>Spenden</button>

                <div className="md:block hidden">
                    <LeagueSelector onLeagueChange={handleLeagueChange} />
                </div>
            </div>
            {
                user ? (
                    <div className="relative flex items-center justify-center space-x-4 md:flex-row md:block hidden">
                        {
                            loading ? (
                                <ClipLoader color={"#ff4601"} size={30} />
                            ) : (
                                <>
                                    <div className="flex items-center space-x-4">
                                        {/* User Info & Dropdown */}

                                        < div className="hidden md:block" >
                                            <button onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in">
                                                Spenden
                                            </button>
                                        </div >
                                        {/* Financial Cards */}
                                        < div className="finance-container space-x-4" >
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Kontostand</span>
                                                <span className="block text-lg">{formatCurrency(budget)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Teamwert</span>
                                                <span className="block text-lg">{formatCurrency(teamValue)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Echter Wert</span>
                                                <span className="block text-lg">{formatCurrency(realValue)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Übriges Budget</span>
                                                <span className="block text-lg">{formatCurrency(investmentLeft)}</span>
                                            </div>
                                        </div >
                                        <div className="flex items-center space-x-2">
                                            <span className="mr-2">{user.name}</span>
                                            <img src={user.profile} alt="User Profile" className="w-8 h-8 rounded-full cursor-pointer" onClick={() => setDropdownOpen(!dropdownOpen)} />
                                        </div>
                                    </div >

                                    {/* View Details button */}
                                    < button className="md:hidden bg-gray-700 p-2 rounded" onClick={() => setShowBudgetModal(true)}> Details</button >
                                    {dropdownOpen && (
                                        <div className="absolute right-1 ease-in-out w-38 bg-gray-700 text-white shadow-lg rounded z-10">
                                            <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-600">Abmelden</button>
                                        </div>
                                    )}
                                </>
                            )}
                    </div >
                ) : (
                    <div></div>
                )}

            {
                showLeagueModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="bg-gray-700 p-4 rounded">
                            <LeagueSelector onLeagueChange={handleLeagueChange} />
                            <button onClick={() => setShowLeagueModal(false)} className="mt-4 block mx-auto bg-red-500 p-2 rounded text-white">Close</button>
                        </div>
                    </div>
                )
            }

            {
                showLogoutModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="flex flex-col justify-center items-center space-y-4 bg-gray-700 p-4 rounded-lg shadow-lg text-white text-sm">
                            <div className="text-center mb-4">
                                Hallo liebe Community! 😊<br />
                                Ich habe dieses Tool mit viel Liebe und Mühe für euch entwickelt. Es ist kostenlos, aber es fallen laufende Kosten an. Jede Unterstützung hilft mir, meine Kosten zu decken. Vielen Dank für eure Unterstützung!
                            </div>
                            <div className="mb-4">
                                <button onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in">
                                    Spenden
                                </button>
                            </div>

                            <div className="flex justify-center items-center space-x-4 mt-15">
                                <button onClick={onLogout} className="block bg-red-500 hover:bg-red-600 p-2 rounded text-white transition duration-200 ease-in">Ausloggen</button>
                                <button onClick={() => setShowLogoutModal(false)} className="block bg-gray-400 hover:bg-gray-500 p-2 rounded transition duration-200 ease-in">Schließen</button>
                            </div>
                        </div>
                    </div>
                )
            }


            {
                showBudgetModal && (
                    <div className="fixed top-0 left-0 w-full h-full  bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="bg-gray-700 p-4 rounded">
                            {/* Repeated finance details for mobile modal view */}
                            <div className="finance-container flex space-x-2">
                                <div className="finance-card p-2 border rounded">
                                    <span className="block  text-white">Kontostand</span>
                                    <span className="block text-lg">{formatCurrency(budget)}</span>
                                </div>
                                <div className="finance-card p-2 border rounded">
                                    <span className="block text-white">Teamwert</span>
                                    <span className="block text-white text-lg">{formatCurrency(teamValue)}</span>
                                </div>
                                <div className="finance-card p-2 border rounded">
                                    <span className="block  text-white">Echter Wert</span>
                                    <span className="block  text-white text-lg">{formatCurrency(realValue)}</span>
                                </div>
                                <div className="finance-card p-2 border rounded">
                                    <span className="block  text-white">Übriges Budget
                                    </span>
                                    <span className="block  text-white text-lg">{formatCurrency(investmentLeft)}</span>
                                </div>
                            </div>
                            <button onClick={() => setShowBudgetModal(false)} className="mt-4 block mx-auto bg-red-500 p-2 rounded text-white">Schließen</button>
                        </div>
                    </div>
                )
            }
        </div >
    ));
};


export default Header;




