import React, { useState, useEffect, useMemo } from 'react';
import { getPlayerStats } from './api';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { usePlayerStore } from './stores/PlayerStore';
import { faSquare, faSoccerBall, faHandshake, faChessRook, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlayerDetailModal = ({ player, onClose }) => {
    const [historicalData, setHistoricalData] = useState([]);
    const [timeInterval, setTimeInterval] = useState(30);
    const [show, setShow] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [sliderDays, setSliderDays] = useState(0);


    const totalGoals = player.seasons?.reduce((acc, season) => acc + season.goals, 0);
    const totalAssists = player.seasons?.reduce((acc, season) => acc + season.assists, 0);
    const totalMatches = player.seasons?.reduce((acc, season) => acc + season.matches, 0);
    const avgGoalsPerMatch = totalMatches ? (totalGoals / totalMatches).toFixed(2) : 0;
    const avgAssistsPerMatch = totalMatches ? (totalAssists / totalMatches).toFixed(2) : 0;
    const playerStore = usePlayerStore();
    const { leagueId } = playerStore;
    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };


    useEffect(() => {
        if (!show) return;

        const fetchData = async () => {
            try {
                const retrievedHistoricalData = await getPlayerStats(leagueId, player.id);
                if (retrievedHistoricalData && retrievedHistoricalData.marketValues) {
                    setHistoricalData(retrievedHistoricalData.marketValues.reverse());  // Flipping the array for latest values first
                }

            } catch (error) {
                console.error("Error fetching player stats: ", error);
            }
        };

        fetchData();

        return () => {
        };
    }, [player]);


    const filteredData = useMemo(() => {
        if (!show) return [];

        const endDateLocal = new Date();
        const startDateLocal = new Date(endDateLocal);
        startDateLocal.setDate(endDateLocal.getDate() - timeInterval);

        // Consider data from the next day if current time is after 18:00
        if (new Date() >= endDateLocal) {
            endDateLocal.setDate(endDateLocal.getDate() + 1);
        }
        const data = historicalData.filter(dataItem => {
            const date = new Date(dataItem.d);
            return date >= startDateLocal && date < endDateLocal; // Notice the "<" for the end date
        });

        return data;
    }, [historicalData, timeInterval, show]);

    const getStatusTextAndColor = (status) => {
        switch (status) {
            case 0:
                return { text: 'fit', color: 'green' };
            case 1:
                return { text: 'Verletzt', color: 'red' };
            case 2:
                return { text: 'Angeschlagen', color: 'yellow' };
            default:
                return { text: 'Unklar', color: 'gray' };
        }
    }

    if (!show) {
        return null;
    }

    const forecastFutureValue = (historicalData, daysIntoFuture, player) => {

        if (!historicalData || historicalData.length < 2) {
            return 0;
        }

        const yesterdayChange = historicalData[0]?.m - historicalData[1]?.m || 0;
        let forecastedValue = historicalData[0]?.m;

        for (let i = 1; i <= daysIntoFuture; i++) {
            let adjustment = 0.44 * yesterdayChange;

            // Adjust based on the day of the week
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + i);
            if (futureDate.getDay() === 0) adjustment *= 1.05;  // Sunday
            if (futureDate.getDay() === 5) adjustment *= 0.95;  // Friday

            // Limit extreme changes
            if (adjustment > 470000) adjustment = 470000;
            if (adjustment < -470000) adjustment = -470000;

            forecastedValue += adjustment;
        }

        // Performance Metrics:
        const recentSeason = player.seasons ? player.seasons[0] : null;
        if (recentSeason) {
            const goalsToMatchesRatio = recentSeason.goals / recentSeason.matches;
            if (goalsToMatchesRatio > 0.5) {
                forecastedValue *= 1.01;  // 1% increase for high goal scoring rate
            }
        }

        // Position Adjustment:
        if (player.position === "Stürmer") {
            forecastedValue *= 1.01;  // 1% increase for strikers
        }

        // Matches Played:
        if (player.matchesTotal > 30) {
            forecastedValue *= 1.01;  // 1% increase for players who played a lot
        }

        // Yellow Cards:
        const yellowCards = player.overallStats ? player.overallStats.yellowCards : 0;
        if (yellowCards > 10) {
            forecastedValue *= 0.99;  // 1% decrease for aggressive players
        }

        // Average Points:
        if (player.averagePoints > 40) {
            forecastedValue *= 1.01;  // 1% increase for high average points
        }

        // Status-based adjustment
        if (player.status === 1) {  // Injured
            forecastedValue *= 0.95;  // 5% decrease
        } else if (player.status === 2) {  // Slightly injured
            if (daysIntoFuture <= 4) {
                forecastedValue *= 0.98;  // 2% decrease for the first 4 days
            }
        }

        return forecastedValue;
    }


    const futureValueWithSliderDays = forecastFutureValue(historicalData, sliderDays, player.marketValue, player.status);
    const futureValueDifferenceColor = futureValueWithSliderDays >= player.marketValue ? 'text-green-500' : 'text-red-500';


    const formatCurrency = (amount) => {
        if (!amount) return '0';
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // Function to calculate increase in value compared to previous days
    const calculateIncrease = (days) => {
        if (historicalData.length <= days) return null;
        const latestValue = historicalData[0].m;
        const previousValue = historicalData[days].m;
        return latestValue - previousValue;
    };

    const formatNumberWithDots = (number) => {
        if (!number) return '0';
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    const statusInfo = getStatusTextAndColor(player.status);
    const handleSliderChange = (e) => {
        setSliderDays(e.target.value);
    };


    const formatIncrease = (days) => {
        const increase = calculateIncrease(days);
        if (increase === null) return null;

        const formattedIncrease = formatCurrency(increase);
        const bgColorClassName = increase > 0 ? 'bg-green-200' : 'bg-red-300';

        return (
            <span className={`inline-block px-4 py-2 tx-black-100 m-1 rounded ${bgColorClassName}`}>
                {days} Tag{days > 1 ? 'e' : ''}: {formattedIncrease}
            </span>
        );
    };



    const calculateFutureValue = (player) => {
        const daysDifference = (new Date(player.marketValueHighDate) - new Date(player.marketValueLowDate)) / (24 * 60 * 60 * 1000);
        const dailyGrowthRate = (player.marketValueHigh - player.marketValueLow) / daysDifference;
        return player.marketValue + (dailyGrowthRate * 7);
    }
    const futureValue = calculateFutureValue(player);
    const formattedDate = new Date(player.marketValueHighDate).toLocaleDateString('de-DE');


    const getStatusClasses = (status) => {
        switch (status) {
            case 0:
                return 'text-green-600 bg-green-600';
            case 1:
                return 'text-black bg-red-600';
            case 2:
                return 'text-black bg-yellow-500';
            default:
                return 'text-gray-500 bg-gray-500';
        }
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-3 shadow">
                    <p className="label" style={{ color: 'black' }}>{new Date(label).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</p>
                    <p className="intro" style={{ color: 'black' }}>
                        Marktwert: {formatNumberWithDots(payload[0].value)} Euro
                    </p>
                </div>
            );
        }
        return null;
    };
    console.log(player)

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-900 rounded-lg shadow-2xl w-full max-w-3xl max-h-[90vh] overflow-y-auto scrollbar-hide relative">
                {/* Close button */}
                <button 
                    onClick={onClose} 
                    className="absolute top-4 right-4 text-white hover:text-gray-300 focus:outline-none"
                    aria-label="Close"
                >
                    <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>

                <div className="p-6 text-white">
                    <h2 className="text-2xl font-semibold mb-6 text-center" style={{ color: '#ff4601' }}>
                        Marktwertentwicklung: {player.name ? player.name : player.firstName + " " + player.lastName}
                    </h2>

                    {/* Status Badge */}
                    <div className={`rounded-full p-2 text-white text-sm mb-4 text-center ${getStatusClasses(player.status)}`}>
                        {getStatusTextAndColor(player.status).text}
                    </div>

                    <div className="bg-gray-700  p-3 rounded shadow-xl w-full mb-6">
                        <div className="flex flex-col space-y-4">
                            {/* Player Market Value Info */}
                            <div className="flex flex-col md:flex-row items-start space-y-2 md:space-y-0 md:space-x-4">
                                {/* Market Value */}
                                <div className="flex-1">
                                    <p className="text-gray-100 font-bold text-md">Aktueller Marktwert:</p>
                                    {player.marketValue && <p className="text-green-500 font-semibold text-lg">{player.marketValue.toLocaleString()} €</p>}
                                </div>
                                {/* Market Value Change */}
                                <div className="flex-1">
                                    <p className="text-gray-100 font-bold text-md">Veränderung:</p>
                                    {player.marketValueIncrease && <p className={player.marketValueIncrease >= 0 ? "text-green-500" : "text-red-500"}>
                                        {player.marketValueIncrease.toLocaleString()} €
                                    </p>}
                                </div>
                            </div>

                            {/* Toggle for More Information */}
                            <div className="flex justify-center mb-4">
                                <button className="text-gray-100 bg-indigo-600 px-4 py-1 rounded-full focus:outline-none hover:bg-indigo-700" onClick={() => setShowMore(!showMore)}>
                                    {showMore ? "Weniger anzeigen" : "Mehr anzeigen"}
                                </button>
                            </div>

                            {showMore && (
                                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                                    {/* High and Low Market Value */}
                                    <div className="flex-1">
                                        <p className="text-gray-100 font-bold text-lg">Höchster Wert:</p>
                                        {player.marketValueHigh && <p className="text-gray-100">{player.marketValueHigh.toLocaleString()} € (Am {new Date(player.marketValueHighDate).toLocaleDateString('de-DE')})</p>}
                                        <p className="text-gray-100 font-bold text-lg mt-2">Niedrigster Wert:</p>
                                        {player.marketValueLow && <p className="text-gray-100">{player.marketValueLow.toLocaleString()} € (Am {new Date(player.marketValueLowDate).toLocaleDateString('de-DE')})</p>}
                                    </div>

                                    {/* Purchase Info */}
                                    {
                                        player.buyPrice > 0 && (
                                            <div className="flex-1">
                                                <p className="text-gray-100 font-bold text-lg">Gekauft für:</p>
                                                <p className="text-grey-100">{player.buyPrice.toLocaleString()} €</p>
                                            </div>
                                        )
                                    }


                                    {/* Future Estimate */}
                                    <div className="mt-4">
                                        <label htmlFor="days-slider" className="block text-sm font-bold mb-2">Prognose:</label>
                                        <input
                                            type="range"
                                            id="days-slider"
                                            min="0"
                                            max="14"
                                            value={sliderDays}
                                            onChange={handleSliderChange}
                                            className="slider w-full mb-4"
                                            style={{
                                                appearance: 'none',
                                                height: '15px',
                                                borderRadius: '5px',
                                                background: '#E5E7EB',
                                                outline: 'none',
                                            }}
                                        />

                                        <p className={`text-lg font-semibold ${futureValueDifferenceColor}`}>In {sliderDays} Tagen: {futureValueWithSliderDays.toLocaleString()} €</p>

                                    </div>


                                    {/* Player Performance Info */}
                                    <div className="flex flex-col space-y-2">
                                        <p className="text-gray-300 font-bold text-lg">Leistung:</p>
                                        <div className="flex items-center mb-4">
                                            <FontAwesomeIcon icon={faChessRook} className="mr-2" />
                                            <p className="text-gray-100">Spiele: {player?.matchesTotal}</p>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <FontAwesomeIcon icon={faSoccerBall} className="mr-2" />
                                            <p className="text-gray-100">Tore: {player.overallStats?.goals}</p>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <FontAwesomeIcon icon={faHandshake} className="mr-2" />
                                            <p className="text-gray-100">Assists: {player.overallStats?.assists}</p>
                                        </div>


                                    </div>
                                    <div className="flex items-center mb-4">
                                        {player.overallStats?.yellowCards !== 0 && (
                                            <>
                                                <FontAwesomeIcon icon={faSquare} className="text-yellow-500 mr-2" />
                                                <span>Gelbe Karten: {player.overallStats?.yellowCards}</span>
                                            </>
                                        )}
                                    </div>

                                    <div className="flex items-center mb-4">
                                        <FontAwesomeIcon icon={faSquare} className="text-red-500 mr-2" />
                                        <span>Rote Karten: {player.overallStats?.redCards}</span>


                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Line Chart */}
                    <div className="w-full mt-4" style={{ maxWidth: 750 }}>
                        <LineChart
                            width={Math.min(window.innerWidth * 0.95, 550)}
                            height={window.innerWidth < 480 ? 150 : 250}
                            data={filteredData.reverse()}
                            margin={{ left: 30 }}
                            className="graph-container"
                        >
                            <Line type="monotone" dataKey="m" stroke="#ff4601" dot={false} />
                            <XAxis dataKey="d" tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                className="md:block hidden"
                            />
                            <Tooltip content={<CustomTooltip />} />
                        </LineChart>
                    </div>

                    {/* Date Range Selector */}
                    <div className="mt-4 w-full flex justify-center">
                        <select
                            value={timeInterval}
                            onChange={e => setTimeInterval(Number(e.target.value))}
                            className="w-64 p-2 rounded shadow bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        >
                            <option value={7}>Letzte 7 Tage</option>
                            <option value={30}>Letzte 30 Tage</option>
                            <option value={90}>Letzte 90 Tage</option>
                            <option value={365}>Letzte 365 Tage</option>
                            <option value={1800}>Alles</option>
                        </select>
                    </div>

                    {/* Market Value History Table */}
                    <div className="responsive-table mt-6">
                        <table className="w-full text-black border-collapse">
                            <thead>
                                <tr>
                                    <th className="border-b-2 px-4 py-2">Datum</th>
                                    <th className="border-b-2 px-4 py-2">Marktwert</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historicalData.map((data, index) => {
                                    const date = new Date(data.d);
                                    let marketValue = data.m;
                                    let difference = null;
                                    if (isToday(date)) {
                                        marketValue = player.marketValue;
                                        if (index < historicalData.length - 1) {
                                            difference = marketValue - historicalData[index + 1].m;
                                        }
                                    } else if (index < historicalData.length - 1) {
                                        difference = marketValue - historicalData[index + 1].m;
                                    }

                                    return (
                                        <tr key={data.d}>
                                            <td className="border px-4 py-2 text-center">
                                                {date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                            </td>
                                            <td className="border px-4 py-2 text-center">
                                                {formatCurrency(marketValue)} Euro
                                                {difference !== null &&
                                                    <span className={difference >= 0 ? "text-green-500" : "text-red-500"}>
                                                        ({difference > 0 ? "+" : ""}{formatCurrency(difference)})
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PlayerDetailModal;