import React, { useState, useEffect, useMemo } from 'react';
import Login from './Login';
import logo from './analyticlogo.png';
import overviewImg from './overview.jpg';
import virtualSalesImg from './virtualSales.jpg';
import transferMarketImg from './transferMarket.jpg';
import scoutingListImg from './scoutingList.jpg';
import topGamesImg from './topGames.jpg';
import budgetCalculatorImg from './budgetCalculator.jpg';
import marketTrendsImg from './marketTrends.jpg';
import playerAlternativesImg from './playerAlternatives.jpg';
import { motion } from 'framer-motion';
import TermsAndConditions from './TermsAndConditions';
import Disclaimer from './Disclaimer';
import PrivacyPolicy from './PrivacyPolicy';
import Modal from 'react-modal';
import CookieBanner from './CookieBanner';
import { CookieProvider } from './CookieContext';

const LandingPage = () => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: '#1F2937', // Adjust the color to match your page's background
            color: 'white', // Text color
            borderRadius: '10px', // Optional: for rounded corners
            border: 'none', // Optional: if you don't want any border
            maxWidth: '80%', // Example maximum width, adjust as needed
            maxHeight: '80%', // Maximum height, adjust as needed
            overflow: 'auto', // Allow scrolling for overflow content
            padding: '20px',
            overflow: 'auto' // Allow scrolling for overflow content
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)' // Dimmed background
        }
    };


    const features = [
        {
            id: 1,
            title: "Dein Kader im Überblick",
            description: "Beobachte Marktwertsteigerungen auf den Cent genau, sogar Wochen zurück.",
            img: overviewImg,
        },
        {
            id: 2,
            title: "Virtueller Spielerhandel",
            description: "Verkaufe deine Spieler virtuell, um zu sehen, wie du bis zum Anpfiff wieder schwarze Zahlen schreibst.",
            img: virtualSalesImg,
        },
        {
            id: 3,
            title: "Marktbeobachtung in Echtzeit",
            description: "Schaue dir Minutengenau an, welche Spieler auf dem Transfermarkt ablaufen und reagiere schneller als andere.",
            img: transferMarketImg,
        },
        {
            id: 4,
            title: "Scoutingliste",
            description: "Füge Spieler zu deiner persönlichen Scoutingliste hinzu und behalte sie im Blick.",
            img: scoutingListImg,
        },
        {
            id: 5,
            title: "Topspiele Überblick",
            description: "Sieh dir die Topspiele deiner Kontrahenten und in der Liga an, um strategische Entscheidungen zu treffen.",
            img: topGamesImg,
        },
        {
            id: 6,
            title: "Budget Planung",
            description: "Schau dir an, was du noch ausgeben kannst. Keine komplizierte Formeln - Kickly macht alles für dich.",
            img: budgetCalculatorImg,
        },
        {
            id: 7,
            title: "Marktwert Trends",
            description: "Schau dir historische Marktwertverläufe an und erkenne frühzeitig Trends. KI-basierte Prognosen helfen dir dabei.",
            img: marketTrendsImg,
        },
        {
            id: 8,
            title: "Spieler Alternativen",
            description: "Finde Alternativen zu deinen Spielern, sowohl auf dem Markt als auch von anderen Spielern, und baue dir so deinen Schattenkader.",
            img: playerAlternativesImg,
        },
    ];


    const [isTermsOpen, setTermsOpen] = useState(false);
    const [isDisclaimerOpen, setDisclaimerOpen] = useState(false);
    const [isPrivacyOpen, setPrivacyOpen] = useState(false);

    const openTermsModal = () => setTermsOpen(true);
    const openDisclaimerModal = () => setDisclaimerOpen(true);
    const openPrivacyModal = () => setPrivacyOpen(true);

    const closeModal = () => {
        setTermsOpen(false);
        setDisclaimerOpen(false);
        setPrivacyOpen(false);
    };


    return (
        <motion.div className="bg-gray-900 w-full text-white"
            initial="hidden"
            animate="visible"
        >

            <div className="container mx-auto px-6 md:px-8 w-full py-8" >

                <header className="text-center mb-12">
                    <motion.div
                        className="mb-8"
                        variants={{
                            hidden: { scale: 0.8, opacity: 0 },
                            visible: { scale: 1, opacity: 1, transition: { duration: 0.5 } }
                        }}
                    >
                        <img src={logo} alt="Kickly" className="w-48 mx-auto" />
                    </motion.div>

        
                    <CookieProvider>
                        <Login />
                    </CookieProvider>
                </header >

                {/* New section explaining Kickly */}
                <section className="mt-16 mb-12 text-center">
                    <h2 className="text-3xl font-bold text-white mb-4">Was ist Kickly?</h2>
                    <p className="text-xl text-gray-300 mb-36">
                        Kickly ist dein kostenloses Tool, um mit datengestützten Erkenntnissen 
                        bessere Entscheidungen in Kickbase zu treffen und deine Strategie zu optimieren.
                    </p>
                </section>

                <main>
                    {features.map((feature, index) => (
                        <Feature
                            key={feature.id}
                            img={feature.img}
                            title={feature.title}
                            description={feature.description}
                            index={index}
                        />
                    ))}
                </main>

                <footer className="text-center mt-0 mb-0">
                    <p className="text-gray-400 text-sm">
                        Kickly steht nicht in Verbindung mit Kickbase und ist ein eigenständiges Produkt.
                    </p>
                    <p className="text-gray-400 text-sm mt-2 mb-2">
                        Die auf dieser App verwendeten Bilder gehören der Bundesliga und der Deutschen Fußball Liga und sind nicht mit dieser App assoziiert.
                    </p>
                    <button onClick={openTermsModal}
                        className="mx-2 px-4 mb-2 py-2 rounded-lg bg-orange-600 text-white font-semibold hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50">
                        Datenschutzerklärung                    </button>
                    <button onClick={openDisclaimerModal}
                        className="mb-0 px-4 py-2 rounded-lg bg-orange-600 text-white font-semibold hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50">
                        Impressum
                    </button>

                </footer>

                <Modal isOpen={isTermsOpen} onRequestClose={closeModal} style={customStyles}>
                    <TermsAndConditions />
                </Modal>

                <Modal isOpen={isDisclaimerOpen} onRequestClose={closeModal} style={customStyles}>
                    <Disclaimer />
                </Modal>

                <Modal isOpen={isPrivacyOpen} onRequestClose={closeModal} style={customStyles}>
                    <PrivacyPolicy />
                </Modal>
            </div>
        </motion.div>
    );
};

const Feature = ({ img, title, description, index }) => {
    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.8 }
        }
    };

    const imageVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: { type: 'spring', stiffness: 260, damping: 20 }
        }
    };
    return (
        <motion.div className={`flex flex-col  items-center`}
            variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { delay: index * 0.1 } }
            }}
        >
            <motion.div
                className="mb-6 flex flex-col items-center text-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={containerVariants}
            >
                <motion.h3
                    className="text-2xl md:text-3xl font-bold mb-3 text-center"
                    variants={containerVariants}
                >
                    {title}
                </motion.h3>
                <motion.p
                    className="text-md md:text-lg text-center"
                    variants={containerVariants}
                >
                    {description}
                </motion.p>


                <div className="w-full lg:w-1/4 mb-8 md:mb-0">
                    <motion.img src={img} alt={title} className="rounded-lg shadow-xl"
                        variants={{
                            hidden: { scale: 0.95, opacity: 0 },
                            visible: { opacity: 1, transition: { delay: index * 0.1 } }
                        }}
                    />
                </div>
            </motion.div>


        </motion.div>

    );
};

export default LandingPage;