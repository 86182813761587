import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const PlayerComparisonCard = ({ player, currentPlayer, isFavorite, toggleFavorite }) => {
    // Helper function to get appropriate arrow icon and color based on comparison
    const getComparisonIndicator = (playerValue, compareValue) => {
        if (playerValue > compareValue) {
            return <FontAwesomeIcon icon={faArrowUp} className="text-green-500 ml-1" />;
        } else if (playerValue < compareValue) {
            return <FontAwesomeIcon icon={faArrowDown} className="text-red-500 ml-1" />;
        } else {
            // If values are equal, you can return null or an equal sign icon
            return null;
        }
    };

    return (
        <div className="bg-gray-800 p-3 rounded-lg shadow-md flex flex-col md:flex-row w-full my-2">
            <img
                src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                alt={player.name}
                className="w-16 h-16 rounded-full object-cover mr-4"
                onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop
                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                }}
            />
            <div className="flex-grow">
                <div className="flex justify-between items-start">
                    <div>
                        <h4 className="font-bold text-lg">
                            {player.name}
                        </h4>
                        <div className="flex items-center text-sm">
                            <span>Marktwert:</span>
                            <span className="ml-2">{player.marketValue.toLocaleString()} €</span>
                            {getComparisonIndicator(player.marketValue, currentPlayer.marketValue)}
                        </div>
                        <div className="flex items-center text-sm">
                            <span>Punkte:</span>
                            <span className="ml-2">{player.totalPoints}</span>
                            {getComparisonIndicator(player.totalPoints, currentPlayer.totalPoints)}
                        </div>
                        <div className="flex items-center text-sm">
                            <span>Punkteschnitt:</span>
                            <span className="ml-2">{player.averagePoints}</span>
                            {getComparisonIndicator(player.averagePoints, currentPlayer.averagePoints)}
                        </div>
                    </div>
                    <FontAwesomeIcon
                        icon={faStar}
                        onClick={() => toggleFavorite(player.id)}
                        className={`text-2xl cursor-pointer ${isFavorite ? 'text-yellow-400' : 'text-gray-400'}`}
                        style={{ marginLeft: 'auto' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlayerComparisonCard;