import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faEuroSign, faLevelUpAlt, faLevelDownAlt, faFilter } from '@fortawesome/free-solid-svg-icons';
import { getPlayerFeed, getPlayerStats } from './api';
import { usePlayerStore } from './stores/PlayerStore';
import botImage from './bot.png';
import ClipLoader from "react-spinners/ClipLoader";

const PlayerTimeline = ({ playerId, onClose }) => {
    const [feedData, setFeedData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const playerStore = usePlayerStore();
    const { leagueId } = playerStore;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFeedAndHistoricalData = async () => {
            try {
                setLoading(true);
                const feed = await getPlayerFeed(leagueId, playerId);
                const validItems = feed.items.filter(item => item.type === 15 && item.meta && (item.meta.s || item.meta.b));
                setFeedData(validItems);

                const retrievedHistoricalData = await getPlayerStats(leagueId, playerId);
                if (retrievedHistoricalData && retrievedHistoricalData.marketValues) {
                    setHistoricalData(retrievedHistoricalData.marketValues.reverse());
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFeedAndHistoricalData();
    }, [playerId, leagueId]);

    const currentMarketValue = historicalData.length > 0 ? historicalData[0].m : null;

    const [filterDate, setFilterDate] = useState(null);

    const formatCurrency = (amount) => {
        if (!amount) return '0';
        return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`;
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    const getPlayerValueOnDate = (date) => {
        const targetDate = new Date(date);
        const sortedData = [...historicalData].sort((a, b) => new Date(b.d) - new Date(a.d));
        const valueOnOrBeforeDate = sortedData.find(value => new Date(value.d) <= targetDate);


        return valueOnOrBeforeDate ? valueOnOrBeforeDate.m : null;
    };

    const calculateTotalProfit = () => {
        return feedData.reduce((acc, event) => {
            const sellingPrice = event?.meta?.v;
            const playerValueAtTimeOfSale = getPlayerValueOnDate(event.date);
            const gapValue = sellingPrice - playerValueAtTimeOfSale;
            return acc + gapValue;
        }, 0);
    };

    const totalProfit = calculateTotalProfit();

    return (
        <div className="p-5 bg-gray-900 rounded-lg shadow-xl overflow-y-auto max-h-screen w-full md:w-2/3 mx-auto">
            {loading ? (
                <div className="flex justify-center items-center min-h-screen">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <>
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold text-white">Spieler Chain</h2>

                    </div>
                    <div className="mb-5">
                        <p className="text-white mb-1">Gesamter Overpay:</p>
                        <span className={`font-bold ${totalProfit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                            {formatCurrency(totalProfit)}
                        </span>
                    </div>
                    {feedData.filter(event => !filterDate || new Date(event.date).toLocaleDateString() === filterDate).map(event => {
                        const purchasePrice = event?.meta?.purchasePrice;
                        const isSellerBot = event?.meta?.s?.n === 'Markt';
                        let sellingPrice = event?.meta?.v;
                        let playerValueAtTimeOfSale = getPlayerValueOnDate(event.date);

                        if (isSellerBot) {
                            sellingPrice = playerValueAtTimeOfSale;
                        }

                        const gapValue = sellingPrice - playerValueAtTimeOfSale;


                        return (
                            <div key={event.id} className="bg-gray-800 rounded-lg p-4 my-4 shadow-md">
                                <div className="flex flex-col md:flex-row items-center justify-between mb-3">
                                    <span className="text-gray-400 mb-2 md:mb-0">{formatDate(event.date)}</span>
                                    <span className="text-blue-400 font-bold">
                                        <FontAwesomeIcon icon={faEuroSign} className="mr-1" />
                                        {sellingPrice && formatCurrency(sellingPrice)}
                                    </span>
                                </div>
                                <div className="flex flex-col md:flex-row items-center justify-between">
                                    <div className="flex items-center mb-2 md:mb-0">
                                        <img src={event?.meta?.s?.f || botImage} alt="Seller" className="w-10 h-10 rounded-full object-cover mr-2" />
                                        <span className="text-white">{event?.meta?.s?.n || 'Markt'}</span>
                                    </div>
                                    <FontAwesomeIcon icon={faExchangeAlt} className="text-blue-500 mx-3 mb-2 md:mb-0" />
                                    <div className="flex items-center">
                                        <img src={event?.meta?.b?.f || botImage} alt="Buyer" className="w-10 h-10 rounded-full object-cover mr-2" />
                                        <span className="text-white">{event?.meta?.b?.n || 'Markt'}</span>
                                    </div>
                                </div>
                                {purchasePrice && (
                                    <div className="text-gray-400">
                                        Profit: {formatCurrency(sellingPrice - purchasePrice)}
                                    </div>
                                )}
                                <div className="mt-3 text-gray-400">
                                    {isSellerBot ? 'Aktueller Marktwert' : 'Wert beim Verkauf'}: {playerValueAtTimeOfSale ? formatCurrency(playerValueAtTimeOfSale) : 'N/A'}
                                </div>
                                <div className="mt-1 text-gray-400 flex items-center">
                                    Overpay:
                                    <span className="ml-2">
                                        {gapValue > 0 ? (
                                            <FontAwesomeIcon icon={faLevelUpAlt} className="text-green-600" />
                                        ) : (
                                            <FontAwesomeIcon icon={faLevelDownAlt} className="text-red-600" />
                                        )}
                                    </span>
                                    <span className="ml-2">{formatCurrency(Math.abs(gapValue))}</span>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default PlayerTimeline;
