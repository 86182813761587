
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-6 md:px-8 lg:px-12 py-8">
            <h1>Privacy Policy</h1>
            <p>Insert the legal text for the Privacy Policy here. This should be drafted by a legal expert.</p>
        </div>
    );
};

export default PrivacyPolicy;
